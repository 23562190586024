import { Routes, Route, useParams } from 'react-router-dom';
import WebCheckIn from '../components/web-check-in';
import MobileWebCheckIn from '../components/mobile-web-check-in';
import NotFound from '../components/not-found';
import { Capacitor } from '@capacitor/core';

// Parse the REACT_APP_AUTHORIZED_SHOPS environment variable into an array
const authorizedShops = process.env.REACT_APP_AUTHORIZED_SHOPS.split(',').map(Number);

const WebCheckInWrapper = () => {
  const { id } = useParams();
  const isValidId = /^\d{5}$/.test(id); // Regular expression to match exactly 5 digits

  // Check if the id is in the list of authorized shops
  const isAuthorized = authorizedShops.includes(Number(id));

  const platform = Capacitor.getPlatform();

  // if (platform === 'web') {
    if (isValidId) {
      return <WebCheckIn />;
    } else {
      return <NotFound />;
    }
  // } else if (platform === 'ios' || platform === 'android') {
  //   return <MobileWebCheckIn />;
  // } else {
  //   return <NotFound />;
  // }
};

function AppRoutes() {
  return (
    <Routes>
      <Route path="/:id" element={<WebCheckInWrapper />} />
      <Route path="/*" element={<WebCheckInWrapper />} />
    </Routes>
  );
}

export default AppRoutes;
