function GuestCard({name, defaultCard, index, onClick, isRemove, isChecked}) {
    
    return (
        defaultCard ? 
            <div onClick={onClick}>
                <input type="checkbox" className="gft-input" />
                    <label className="guest-add">
                        <span className="icon " data-icon="gfont-add-person">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="#ffffff">
                                <path
                                    d="M36.5 28v-6.5H30v-3h6.5V12h3v6.5H46v3h-6.5V28ZM18 23.95q-3.3 0-5.4-2.1-2.1-2.1-2.1-5.4 0-3.3 2.1-5.4 2.1-2.1 5.4-2.1 3.3 0 5.4 2.1 2.1 2.1 2.1 5.4 0 3.3-2.1 5.4-2.1 2.1-5.4 2.1ZM2 40v-4.7q0-1.75.875-3.175Q3.75 30.7 5.4 30q3.75-1.65 6.675-2.325Q15 27 18 27t5.9.675q2.9.675 6.65 2.325 1.65.75 2.55 2.15.9 1.4.9 3.15V40Zm3-3h26v-1.7q0-.8-.45-1.525-.45-.725-1.2-1.075-3.55-1.65-6-2.175Q20.9 30 18 30q-2.9 0-5.375.525T6.6 32.7q-.75.35-1.175 1.075Q5 34.5 5 35.3Zm13-16.05q1.95 0 3.225-1.275Q22.5 18.4 22.5 16.45q0-1.95-1.275-3.225Q19.95 11.95 18 11.95q-1.95 0-3.225 1.275Q13.5 14.5 13.5 16.45q0 1.95 1.275 3.225Q16.05 20.95 18 20.95Zm0-4.5ZM18 37Z">
                                </path>
                            </svg>
                        </span>
                        <span>New Guest</span>
                    </label>
            </div>
        :
        <div>
            <input type="checkbox" className={`gft-input ${isRemove? "remove" : ""}`} id={`guest_select_input_${index}`} onClick={onClick} defaultChecked={isChecked} />
            <label htmlFor={`guest_select_input_${index}`}> 
                <span>{name}</span>
            </label>
        </div>
    )
}

export default GuestCard