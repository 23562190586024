import React, { createRef, useState, useEffect, useRef } from "react";

function OTPInput({isActive, isDisabled, onChange, resetIndicator, onSubmit}) {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const otpInputRefs = Array(6).fill().map(() => createRef());
  const [iOSMessage , setIOSMessage] = useState("");
  const otpRef = useRef(otp);

  function isIOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  }

  const focusNext = (event, nextField) => {
    if (nextField < otp.length) {
          otpInputRefs[nextField].current.focus();
    }
  };
  
  const focusPrev = (prevField) => {
    if (prevField >= 0) {
      otpInputRefs[prevField].current.focus();
    } 
  };
  
  const updateOtp = (value, index) => {
    setOtp(otp.map((digit, idx) => (idx === index ? value : digit)));
  };

  const updateInputs = (value) => {
    if (value.length === otp.length && !isNaN(value)) {
      setOtp(value.split(''));
    }
    otpInputRefs[Math.min(otpInputRefs.length-1,value.length)].current.focus();
  }

  const handleChange = (event, idx) => {
    if (event.type === 'paste') {
        const eventData = event.clipboardData.getData('text/plain');
        updateInputs(eventData);
    } 
    else if (event.type === 'change' && event.nativeEvent.inputType === 'deleteContentBackward') {
      if (event.target.value === '' && event.nativeEvent.inputType === 'deleteContentBackward') {
        // If the current input is empty and the backspace key is pressed
        updateOtp('', idx); // Clear the previous input
        focusPrev(Math.max(idx - 1,0)); // Focus on the previous input
      }
    } 
    else if (event.type === 'change') {
      if (isIOS()) {
        // Handle iOS paste
          if (event.target.value) {
            const value = event.target.value;
            setTimeout(updateOtp(value, idx),50);
          }
      }
      else {
      const eventData = event.nativeEvent.data;
        if (eventData) {
          if (eventData.length > 1) { 
              updateInputs(eventData);
          } else {
              updateOtp(eventData, idx);
          }
        }
      }
    }
  }

  const handlePaste = (event, idx) => {
    event.preventDefault();
    const eventData = event.clipboardData.getData('text/plain');
    setTimeout(() => updateInputs(eventData), 50); // Add delay
  };

  useEffect(() => {
    // pass the otp value back up whenever it changes
    onChange (otp.join(''));
  }, [otp, onChange]);

  useEffect(() => {
    // reset OTP value when resetIndicator changes
    setOtp(Array(6).fill(""));
  }, [resetIndicator]);

  useEffect(() => {
    // if active and not disabled, focus on first field
    if (isActive && !isDisabled) {
      otpInputRefs[0].current.focus();
    }
  }, [isActive, isDisabled]);

  return (
    <div className="inputs d-flex flex-wrap flex-row align-items-center column-gap-1 row-gap-1">
      {otp.map((digit, idx) => (
        <input
          key={idx}
          className="text-center form-control otp"
          id={`otp${idx}`}
          type="tel"
          pattern="[0-9]{1}"
          inputMode="numeric"
          value={digit}
          onChange={e => handleChange(e,idx)}
          onFocus={e => e.target.select()}
          onKeyDown={e => {
            if (e.key === 'Backspace') {
              focusPrev(e, idx - 1);
            }else if (e.key === 'Enter' && otp.length === 6) {
              onSubmit()
            }
          }}
          onPaste={e => handlePaste(e,idx)}
          onInput={e => focusNext(e, idx + 1)}
          ref={otpInputRefs[idx]}
          disabled={isDisabled || !isActive}
          autoComplete="off"
        />
      ))}
    </div>
  );
}

export default OTPInput;
