import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './app/store';
import { Provider } from 'react-redux'

function Root() {
  
  return (
    //<React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    //</React.StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById('webCheckin'));
root.render(<Root />);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
