import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import BreadCrumbs from "../interface/breadcrumbs";
import FinalSummaryGuestCard from '../interface/final-summary-guest-card';
import { setCurrentStep, setCurrentGuest, setUsers, resetState, setResetState } from "../../app/store";
import { Steps } from "../../util/helpers";
import ActionButton from '../interface/button'

function FinalConfirmation({isMobile, cancelServiceCallback}) {
    const dispatch = useDispatch();
    const checkInData = useSelector((state) => state.webCheckIn);
    const [timeout, setTimeoutValue] = useState(60); // Initial timeout value in seconds
    const [isActive, setIsActive] = useState(true);
   
    const handleCancelService = (ticketId, userId) => {
        return cancelServiceCallback(ticketId, userId);
    }
    
    const handleNext = () => {
        dispatch(setCurrentGuest(0));
        dispatch(setUsers([]));
        dispatch(setCurrentStep(Steps.GUEST_SELECT));
    }

    const getLowestWaitTime = (tickets, users) => {
        if (!tickets || tickets.length === 0 || !users || users.length === 0) {
          return null; // or some other default value
        }
      
        // Create a Set of user IDs for faster lookup
        const userIds = new Set(users.map(user => user.id));
      
        // Filter the tickets based on whether the visitorId exists in userIds
        const filteredTickets = tickets.filter(ticket => userIds.has(ticket.visitorId));
      
        // If no filtered tickets, return null or some other default value
        if (filteredTickets.length === 0) {
          return null;
        }
      
        // Extract waitTimes and find the minimum
        const waitTimes = filteredTickets.map(ticket => parseInt(ticket.waitTime, 10));
        return Math.min(...waitTimes);
    };

    // Effect for the countdown
    useEffect(() => {
        if (timeout <= 0) return; // No need to start the interval if timeout is already 0

        // Update the countdown every second
        const countdownTimer = setInterval(() => {
            if (timeout > 0) {
                setTimeoutValue(timeout - 1);
            }
        }, 1000);

        // Clear the countdown timer if the component unmounts
        return () => {
            clearInterval(countdownTimer);
        };
    }, [timeout]);

    //Effect that triggers the resetState function after a 60-second delay on page load
    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(setResetState(true));
            dispatch(resetState());
            dispatch(setResetState(false));
        }, 60000); // 60 seconds

        // Clear the timer if the component unmounts
        return () => {
            clearTimeout(timer);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Reset to Select Guest on All Guests cancelled
    useEffect(() => {
        if (checkInData.users.length === 0) {
            dispatch(setCurrentGuest(0));
            dispatch(setCurrentStep(Steps.GUEST_SELECT))
        }
    },[checkInData.users, dispatch])

    return (
        <div className="row w-100 p-0 py-1 py-lg-5 m-0 flex-column flex-lg-row h-100">
        <div className="col-lg-6 order-3 order-lg-2 h-100 d-flex tab-content">        
            <div id="nav-gft_embed_summary" className="col-12 h-100 w-100 flex-column px-0 px-md-5 m-0 gft-content flex-nowrap">
                <div className="gft-header user-select-none">
                    <h2 className="gft-view-heading mb-3">{checkInData.users.some(user => user.selectedServices && user.selectedServices.length > 1) ? "Your services are confirmed!" : "Your service is confirmed"}</h2>
                    <p className="gft-view-desc mb-3 mb-lg-5">We have confirmed your services with the Shop. Please find your updated estimated wait times below.</p>
                </div>
                <form id="gftForm-gft_embed_summary" className="gft-form gft-summary">
                    {checkInData.users?.map((user,userIndex) => {
                        return (
                            <FinalSummaryGuestCard
                                key={user.id}
                                userIndex={userIndex}
                                user={user}
                                waitTime={checkInData.tickets?.find(ticket => ticket.visitorId === user.id && ticket.shopId === parseInt(checkInData.shopInfo?.hours.id))?.waitTime}
                                isMobile={isMobile}
                                checkInData={checkInData}
                                cancelServiceCallback={handleCancelService}
                            />
                        )
                    })}
                </form>
                <div className={`gft-footer ${isMobile ? 'position-relative' : ''}`}>
                  <div className="gft-buttons d-flex flex-column m-lg-0 row-gap-3">
                    <div className="text-center w-100">Returning to Home screen in {timeout} seconds</div>
                    <ActionButton btnPrimary={true} text="Home" onClick={handleNext} isActive={isActive} arrowRight={process.env.REACT_APP_DEV_ENABLED ? true : checkInData.shopInfo?.shopStatus !== "Open" ? false : true} isDisabled={false} />
                </div>
                </div>
            </div>
        </div>
        <BreadCrumbs crumbs={true} activeCrumb="final-summary" backButton={false} backFunction={()=>{}} waitTimeOverride={getLowestWaitTime(checkInData.tickets, checkInData.users)} />
    </div>
    )
}

export default FinalConfirmation;