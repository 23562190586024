import AppModal from "./app-modal";
import '../App.css';

function NotFound(){
    return (
            <AppModal 
                 modalState={true}
                 shouldRedirect={true}
                 title="Ooops!"
                 message="You shouldn't be here. Don't worry, we'll get you back on track!"
                 buttons={{}}
                 onModalClose={()=>{}}
                 />
    )
}

export default NotFound