import { formatShopHoursTime } from './time-formatting'
import { Capacitor } from '@capacitor/core';

export function getNextOpenTime(checkInData) {
  const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  // Get current date and time
  let currentDate = new Date();
  
  // Get client's timezone offset in hours
  const clientOffsetInHours = -currentDate.getTimezoneOffset() / 60;

  // Get timezone difference between client and shop
  const timezoneDifference = clientOffsetInHours - checkInData.shopInfo.hours.utcOffset;

  // Get current day index and name
  let currentDayIndex = currentDate.getUTCDay();
  let currentDayName = daysOfWeek[currentDayIndex];

  // Find the next open day
  const findNextOpenDay = (currentIndex) => {
    for (let i = 1; i <= 7; i++) {
      let nextDayIndex = (currentIndex + i) % 7;
      let nextDayName = daysOfWeek[nextDayIndex];
  
      // If the shop is not closed on the next day, get its opening time and return it
      if (!checkInData.shopInfo.hours.openHours[nextDayName].is_closed && checkInData.shopInfo.hours.openHours[nextDayName].open_time) {
        let [nextOpenHour, nextOpenMinute, nextOpenSecond] = checkInData.shopInfo.hours.openHours[nextDayName].open_time.split(':').map(Number);
        let adjustedNextOpenHour = nextOpenHour + timezoneDifference;
    
        // Check if the adjusted opening time is within [0, 24] hours
        if (adjustedNextOpenHour < 0) {
          adjustedNextOpenHour += 24;
        } else if (adjustedNextOpenHour >= 24) {
          adjustedNextOpenHour -= 24;
        }
    
        return `${nextDayName.charAt(0).toLocaleUpperCase() + nextDayName.slice(1)} at ${formatShopHoursTime(`${adjustedNextOpenHour.toString().padStart(2, '0')}:${nextOpenMinute.toString().padStart(2, '0')}:${nextOpenSecond.toString().padStart(2, '0')}`)}`;
      }
    }
  
    // If the shop is closed for the whole week, return null
    return null;
  };

  // Check if the shop is open on the current day
  if (!checkInData.shopInfo.hours.openHours[currentDayName].is_closed && checkInData.shopInfo.hours.openHours[currentDayName].open_time) {
    let [currentOpenHour, currentOpenMinute, currentOpenSecond] = checkInData.shopInfo.hours.openHours[currentDayName].open_time.split(':').map(Number);
    let adjustedCurrentOpenHour = currentOpenHour + timezoneDifference;
  
    // Check if the adjusted opening time is within [0, 24] hours
    if (adjustedCurrentOpenHour < 0) {
      adjustedCurrentOpenHour += 24;
      currentDayIndex = (currentDayIndex === 0) ? 6 : currentDayIndex - 1;
      currentDayName = daysOfWeek[currentDayIndex];
    } else if (adjustedCurrentOpenHour >= 24) {
      adjustedCurrentOpenHour -= 24;
      currentDayIndex = (currentDayIndex + 1) % 7;
      currentDayName = daysOfWeek[currentDayIndex];
    }
  
    return `Today at ${formatShopHoursTime(`${adjustedCurrentOpenHour.toString().padStart(2, '0')}:${currentOpenMinute.toString().padStart(2, '0')}:${currentOpenSecond.toString().padStart(2, '0')}`)}`;
  }

  // If the shop is closed or doesn't have an opening time for the current day, find the next open day
  return findNextOpenDay(currentDayIndex);
}

export const Steps = {
  DEV_GATEWAY: 0,
  LOGIN: 1,
  VERIFICATION: 2,
  REGISTER: 3,
  GUEST_SELECT: 4,
  MANAGE_ACCOUNT: 5,
  MANAGE_GUESTS: 6,
  MANAGE_TRUSTED_DEVICES:7,
  ADD_GUEST: 8,
  GROUP_SERVICES: 9,
  SELECT_SERVICES: 10,
  SELECT_BARBER: 11,
  SUMMARY: 12,
  FINAL_CONFIRMATION: 13,
  SHOP_LOCATOR: 14,
};

export const Platform = Capacitor.getPlatform();

// Function to reverse the keys and values
const reverseMapping = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    acc[obj[key]] = key;
    return acc;
  }, {});
};

const reverseSteps = reverseMapping(Steps);

// Function to get the step name based on its value
export const getStepName = (value) => {
  return reverseSteps[value];
};

export const defaultMapStyles = [
  {
    elementType: "geometry",
    stylers: [{ color: "#f5f5f5" }],
  },
  {
  elementType: "labels.icon",
  stylers: [
      {
      visibility: "off",
      },
  ],
  },
  {
  elementType: "labels.text.fill",
  stylers: [
      {
      color: "#616161",
      },
  ],
  },
  {
  elementType: "labels.text.stroke",
  stylers: [
      {
      color: "#f5f5f5",
      },
  ],
  },
  {
  featureType: "administrative.land_parcel",
  elementType: "labels.text.fill",
  stylers: [
      {
      color: "#bdbdbd",
      },
  ],
  },
  {
  featureType: "poi",
  elementType: "geometry",
  stylers: [
      {
      color: "#eeeeee",
      },
  ],
  },
  {
  featureType: "poi",
  elementType: "labels.text.fill",
  stylers: [
      {
      color: "#757575",
      },
  ],
  },
  {
  featureType: "poi.park",
  elementType: "geometry",
  stylers: [
      {
      color: "#e5e5e5",
      },
  ],
  },
  {
  featureType: "poi.park",
  elementType: "labels.text.fill",
  stylers: [
      {
      color: "#9e9e9e",
      },
  ],
  },
  {
  featureType: "road",
  elementType: "geometry",
  stylers: [
      {
      color: "#ffffff",
      },
  ],
  },
  {
  featureType: "road.arterial",
  elementType: "labels.text.fill",
  stylers: [
      {
      color: "#757575",
      },
  ],
  },
  {
  featureType: "road.highway",
  elementType: "geometry",
  stylers: [
      {
      color: "#dadada",
      },
  ],
  },
  {
  featureType: "road.highway",
  elementType: "labels.text.fill",
  stylers: [
      {
      color: "#616161",
      },
  ],
  },
  {
  featureType: "road.local",
  elementType: "labels.text.fill",
  stylers: [
      {
      color: "#9e9e9e",
      },
  ],
  },
  {
  featureType: "transit.line",
  elementType: "geometry",
  stylers: [
      {
      color: "#e5e5e5",
      },
  ],
  },
  {
  featureType: "transit.station",
  elementType: "geometry",
  stylers: [
      {
      color: "#eeeeee",
      },
  ],
  },
  {
  featureType: "water",
  elementType: "geometry",
  stylers: [
      {
      color: "#c9c9c9",
      },
  ],
  },
  {
  featureType: "water",
  elementType: "labels.text.fill",
  stylers: [
      {
      color: "#9e9e9e",
      },
  ],
  },
];