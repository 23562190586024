import { useDispatch, useSelector } from 'react-redux';
import { setCurrentStep } from '../../app/store'
import ActionButton from '../interface/button'
import BreadCrumbs from "../interface/breadcrumbs";
import { Steps } from "../../util/helpers";

function ManageAccount({isMobile, device:{udf,udi}}) {
    const checkInData = useSelector((state) => state.webCheckIn);
    const dispatch = useDispatch();

    const handleBack = () => {
        dispatch(setCurrentStep(Steps.GUEST_SELECT));
    }

    const handleAddGuest = () => {
        dispatch(setCurrentStep(Steps.ADD_GUEST));
    }

    const handleTrustedDevices = () => {
        dispatch(setCurrentStep(Steps.MANAGE_TRUSTED_DEVICES));
    }

    const handleManageGuests = () => {
        dispatch(setCurrentStep(Steps.MANAGE_GUESTS));
    }

    return (
        <>
            <div className="row w-100 p-0 py-1 py-lg-5 m-0 flex-column flex-lg-row h-100">
                <div id="" className="col-lg-6 order-3 order-lg-2 h-100 d-flex tab-content">
                    <div id="nav-gft_embed_select_guest"
                        className="col-12 h-100 w-100 flex-column px-0 px-md-5 m-0 gft-content flex-nowrap">
                        <div className="gft-header">
                            <h2 className="gft-view-heading mb-3">Account Management</h2>
                            <p className="gft-view-desc mb-3 mb-lg-5">From here you can Add/Remove Guests, and manage any Trusted Devices.</p>
                                <div className="d-flex flex-shrink-1 flex-row flex-nowrap column-gap-1 row-gap-3 justify-content-start mb-3">
                                    <button className="d-flex flex-shrink-1 align-items-center badge text-bg-light rounded text-uppercase btn" id="nav-gft_embed_manage_guest" data-bs-toggle="tab" data-bs-target="#nav-gft_embed_manage_guest" type="button" role="tab" aria-controls="nav-gft_embed_manage_guest" aria-selected="true" onClick={handleAddGuest}>
                                        <span className="icon " data-icon="gfont-edit-person"> 
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-person-add" viewBox="0 0 16 16">
                                            <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Zm-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"/>
                                            <path d="M8.256 14a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Z"/>
                                        </svg>
                                        </span>
                                        <span className="ms-2 small-text">Add</span>
                                    </button>
                                    <button className="d-flex flex-shrink-1 align-items-center badge text-bg-light rounded text-uppercase btn" id="nav-gft_embed_manage_guest" data-bs-toggle="tab" data-bs-target="#nav-gft_embed_manage_guest" type="button" role="tab" aria-controls="nav-gft_embed_manage_guest" aria-selected="true" onClick={handleManageGuests}>
                                        <span className="icon " data-icon="gfont-edit-person"> 
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-person-dash" viewBox="0 0 16 16">
                                            <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM11 12h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1 0-1Zm0-7a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"/>
                                            <path d="M8.256 14a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Z"/>
                                        </svg>
                                        </span>
                                        <span className="ms-2 small-text">Remove</span>
                                    </button>
                                { checkInData?.user?.trustedDevices?.length !== 0 ? 
                                <>
                                    <button className="d-flex flex-shrink-1 badge align-items-center text-bg-light rounded text-uppercase btn" id="nav-gft_embed_manage_guest" data-bs-toggle="tab" data-bs-target="#nav-gft_embed_manage_guest" type="button" role="tab" aria-controls="nav-gft_embed_manage_guest" aria-selected="true" onClick={handleTrustedDevices}>
                                        <span className="icon" data-icon="gfont-edit-person"> 
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-gear-fill" viewBox="0 0 16 16">
                                                <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
                                            </svg>
                                        </span>
                                        <span className="ms-2 small-text">Trusted Devices</span>
                                    </button>
                                    </>
                                    :
                                    null
                                }
                                </div>
                        </div>
                        <div className="gft-footer">
                            <div className="gft-buttons d-flex flex-row column-gap-3">
                                { !isMobile ?
                                    <ActionButton text="Back" onClick={handleBack} isActive={true} arrowLeft={true} />
                                :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <BreadCrumbs crumbs={true} activeCrumb={"guestSelect"} backButton={true} backFunction={handleBack} isMobile={isMobile} />
            </div>
        </>
    )
}

export default ManageAccount
