import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setUsers, setCurrentStep, resetState, setAvailableUsers, setResetState, setTickets } from '../../app/store'
import { Fetch } from '../../util/api';
import ActionButton from '../interface/button'
import GuestCard from "../interface/guest-card-v2";
import BreadCrumbs from "../interface/breadcrumbs";
import AppModal from "../app-modal";
import { Steps, getStepName } from "../../util/helpers";
import { logPageView } from "../../app/analytics";

function GuestSelect({isMobile, cancelServiceCallback}) {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const checkInData = useSelector((state) => state.webCheckIn);
    const selectedGuestsCheckIn = useSelector((state) => state.webCheckIn.users);
    //const selectGuestGroup = useSelector((state) => state.webCheckIn.groupedUsers);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isActive, setIsActive] = useState(true);
    const [modalState, setModalState] = useState(false)
    const containerRef = useRef(null);
    const [hasOverflow, setHasOverflow] = useState(false)
    const [scrollPosition, setScrollPosition] = useState('top')
    const formRef = useRef(null);

    const handleManageAccount = () => {
        dispatch(setCurrentStep(Steps.MANAGE_ACCOUNT));
    }
    
    const handleBack = () => {
        setModalState(true);
    }

    const handleNext = () => {
        dispatch(setCurrentStep(Steps.SELECT_SERVICES));
    }

    const handleGuestCardClick = (guest) => {
        if (isSelected(guest)) {
          const updatedUsers = selectedGuestsCheckIn.filter((user) => user.id !== guest.id);
          dispatch(setUsers(updatedUsers));
        } else {
          const updatedUsers = [...selectedGuestsCheckIn, guest];
          dispatch(setUsers(updatedUsers));
        }
    };

    const isSelected = (guest) => {
        return selectedGuestsCheckIn.some((selectedGuest) => selectedGuest.id === guest.id);
    };

    const okFunction = () => {
        logPageView("user_logged_out",{
            shop_name: checkInData?.shopInfo?.hours?.shopName,
            shop_country: checkInData?.shopInfo?.country,
            page: getStepName(checkInData.currentStep).toString(),
            wait_time: checkInData?.shopInfo?.waitTime,
            shop_status: checkInData?.shopInfo?.shopStatus,
        })
        setModalState(false)
        dispatch(setResetState(true));
        dispatch(resetState());
        dispatch(setResetState(false));
    }

    const cancelFunction = () => {
        setModalState(false)
    }

    useEffect(() => {
        if (checkInData.availableUsers.length === 0 || !checkInData.availableUsers.includes(checkInData.user.formattedNumber)) {
            setLoading(true);
            setTimeout(() => {
                Fetch(`/webcheckin/GetAccountsForPhoneNumber/${checkInData?.user?.formattedNumber}`)
                    .then((response) => {
                        dispatch(setAvailableUsers(response));
                        // Automatically select guest if there is only one
                        if (response.length === 1) {
                            dispatch(setUsers(response));
                        }
                    })
                    .then(() => {
                        const body = {
                            phoneNumber: checkInData.user.formattedNumber
                        }
                        Fetch(`/webcheckin/CleanTicketsForPhoneNumber`,'POST', JSON.stringify(body))
                            .then(data => {
                                if (data.length > 0) {
                                    dispatch(setTickets(data));
                                    const updatedUsers = checkInData.users.filter(user => {
                                        return !data.some(ticket => ticket.visitorId === user.id)
                                    });
                                    dispatch(setUsers(updatedUsers));
                                }
                                setLoading(false);
                                setIsActive(true);
                            })
                            .catch(error => {
                                return error
                            })
                    })
                    .catch((error) => {
                        // Send to GA TGM
                        logPageView("select_guest_error",{
                            shop_name: checkInData?.shopInfo?.hours?.shopName,
                            shop_country: checkInData?.shopInfo?.country,
                            page: getStepName(checkInData.currentStep).toString(),
                            wait_time: checkInData?.shopInfo?.waitTime,
                            shop_status: checkInData?.shopInfo?.shopStatus,
                            error_message: 'Unable to fetch account details, server error',
                        })
                        setErrorMessage("Unable to fetch account details")
                        setShowError(true);
                        setLoading(false);
                        setIsActive(true);
                    })
            }, 1000)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkContainerHeight = () => { 
    if (containerRef.current) {
        const contentHeight = containerRef.current.scrollHeight;
        const containerHeight = containerRef.current.parentNode.clientHeight;

        if (contentHeight > containerHeight) {
        setHasOverflow(true);
        } else {
        setHasOverflow(false);
        }
    }
    }

    useEffect(() => {
    // Check for Services Height
    const resizeObserver = new ResizeObserver(() => {
        checkContainerHeight();
    });

    const divRef = containerRef.current
    if (divRef) {
        resizeObserver.observe(divRef);
    }

    return () => {
        if (divRef) {
        resizeObserver.unobserve(divRef);
        }
    };  
    },[checkInData.users]);
    
    const handleCancelService = (ticketId) => {
        return cancelServiceCallback(ticketId);
    }

    const handleGuestCardError = (error) => {
        // Send to GA TGM
        logPageView("cancellation_error",{
            shop_name: checkInData?.shopInfo?.hours?.shopName,
            shop_country: checkInData?.shopInfo?.country,
            page: getStepName(checkInData.currentStep).toString(),
            wait_time: checkInData?.shopInfo?.waitTime,
            shop_status: checkInData?.shopInfo?.shopStatus,
            error_message: 'Unable to cancel check-in, server error',
        })
        setErrorMessage(`Unable to cancel Check-In, please contact the Shop: ${checkInData.shopInfo.businessNumber} `)
        setShowError(true);
    }

    useEffect(() => {
        const handleScroll = () => {
            const { scrollTop, scrollHeight, clientHeight } = formRef.current;
            
            if (scrollTop === 0) {
              setScrollPosition('top');
            } else if (scrollTop + clientHeight === scrollHeight) {
              setScrollPosition('bottom');
            } else {
              setScrollPosition('other');
            }       
        };
    
        if (formRef.current) {
          formRef.current.addEventListener('scroll', handleScroll);
        }
  
        return () => {
          if (formRef.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            formRef.current.removeEventListener('scroll', handleScroll);
          }
        };
      }, [checkInData.users]);

    return (
        <>
        <AppModal 
            modalState={modalState}
            title="Are you sure?"
            message="This action will log you out, please select 'LOG OUT' to confirm or 'CANCEL' to continue."
            buttons={{ close: false, ok: true, cancel: true, retry: false, loading: false }}
            onModalClose={() => setModalState(prevModalState => false)}
            okFunction={okFunction}
            cancelFunction={cancelFunction}
        />
        {!modalState &&
            <div className="row w-100 p-0 py-1 py-lg-5 m-0 flex-column flex-lg-row h-100">
                <div id="" className="col-lg-6 order-3 order-lg-2 h-100 d-flex tab-content">
                    <div id="nav-gft_embed_select_guest"
                        className="col-12 h-100 w-100 flex-column px-0 px-md-5 m-0 gft-content  flex-nowrap">
                        <div className="gft-header">
                            <h2 className="gft-view-heading mb-3">Who's checking in?</h2>
                            <p className="gft-view-desc mb-3 mb-lg-5">Please select everyone you would like to check-in!</p>
                            { loading ? null : 
                                <div className="d-flex flex-row flex-nowrap column-gap-3 justify-content-end mb-3">
                                    <button className="d-flex align-items-center badge text-bg-light rounded text-uppercase btn" id="nav-gft_embed_manage_guest" data-bs-toggle="tab" data-bs-target="#nav-gft_embed_manage_guest" type="button" role="tab" aria-controls="nav-gft_embed_manage_guest" aria-selected="true" onClick={handleManageAccount}>
                                        <span className="icon " data-icon="gfont-edit-person"> 
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-gear-fill" viewBox="0 0 16 16">
                                                <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
                                            </svg>
                                        </span>
                                        <span className="ms-2 small-text">Manage Account</span>
                                    </button>
                                </div>
                            }
                        </div>
                        { loading ? 
                            <div className="align-items-start mx-auto loading-text">
                                <ActionButton 
                                    btnPrimary={true} 
                                    text="Loading Guests" 
                                    isActive={false} 
                                    onClick={()=>{}} 
                                    noBg={true} 
                                />
                            </div>
                        : 
                            <form id="gftForm-gft_embed_select_guest" className="gft-form" ref={formRef}>
                                <div id="scroll-shadow" className={`gft-barber-wrapper ${hasOverflow ? scrollPosition === 'top' ? 'scroll-shadow scroll-shadow-btm' : scrollPosition === 'bottom' ? 'scroll-shadow scroll-shadow-top' : 'scroll-shadow scroll-shadow-top scroll-shadow-btm' : ''}`} ref={containerRef}>
                                    { checkInData.availableUsers?.length > 0 ? (
                                        checkInData.availableUsers.map((user, index) => {
                                            return (
                                                <GuestCard
                                                    key={user.id}
                                                    index={index}
                                                    name={user.firstName + " " + user.lastName}
                                                    onClick={()=>{handleGuestCardClick(user)}}                                                  
                                                    isSelected={(Array.isArray(selectedGuestsCheckIn) && selectedGuestsCheckIn?.some(checkedUser => checkedUser.id === user.id)) || false}
                                                    isDisabled={!isActive || checkInData.tickets?.some(ticket => ticket.visitorId === user.id && ticket.shopId === parseInt(checkInData.shopInfo?.hours.id))}
                                                    waitTime={checkInData.tickets?.find(ticket => ticket.visitorId === user.id && ticket.shopId === parseInt(checkInData.shopInfo?.hours.id))?.waitTime || undefined}
                                                    isMobile={isMobile}
                                                    checkInData={checkInData}
                                                    user={user}
                                                    cancelServiceCallback={handleCancelService}
                                                    guestCardErrorCallback={()=>handleGuestCardError}
                                                />
                                            );
                                        })
                                        ) : null
                                    }
                                </div>
                            </form>
                        }
                        <div id="gft_error_checkin" className={`gft_error ${ showError ? '' : 'visually-hidden'}`}>{errorMessage}</div>
                        <div className="gft-footer">
                            <div className="gft-buttons d-flex flex-row column-gap-3">
                                { !isMobile ?
                                    <ActionButton text="Back" onClick={handleBack} isActive={true} arrowLeft={true} />
                                :
                                    null
                                }
                                <ActionButton btnPrimary={true} text="Next" onClick={handleNext} isActive={isActive} arrowRight={process.env.REACT_APP_DEV_ENABLED ? true : checkInData.shopInfo?.shopStatus !== "Open" ? false : true} isDisabled={selectedGuestsCheckIn.length === 0 ? true : process.env.REACT_APP_DEV_ENABLED ? false : checkInData.shopInfo?.shopStatus !== 'Open' ? true : false} />
                            </div>
                        </div>
                    </div>
                </div>
                <BreadCrumbs crumbs={true} activeCrumb={"guestSelect"} backButton={true} backFunction={handleBack} isMobile={isMobile} />
            </div>
        }
        </>
    )
}

export default GuestSelect
