import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { removeUserFromUsers, removeUserFromAvailableUsers, setCurrentStep } from "../../app/store";
import ActionButton from '../interface/button'
import { Fetch } from '../../util/api';
import GuestCard from "../interface/guest-card-v2";
//import AppModal from "../app-modal";
import BreadCrumbs from "../interface/breadcrumbs";
import { Steps } from "../../util/helpers";

function ManageGuests({isMobile}) {
    const [loading] = useState(false);
    const dispatch = useDispatch();
    const checkInData = useSelector((state) => state.webCheckIn);
    const [isNextActive, setIsNextActive] = useState(true);
    // const [showError, setShowError] = useState(false);
    // const [errorMessage, setErrorMessage] = useState("");
    const [selectedGuests, setSelectedGuests] = useState([]);
    
    const handleBack = () => {
        dispatch(setCurrentStep(Steps.MANAGE_ACCOUNT))
    }

    const handleRemoveGuests = () => {
        setIsNextActive(false);
        const removeGuests = selectedGuests.map((guest) => {
          return {
            phoneNumber: guest.phoneNumber,
            shortcutsId: guest.shortcutsId,
          };
        });
        Fetch('/webcheckin/RemoveLinkedUser', "POST", JSON.stringify(removeGuests))
          .then((response) => {
            selectedGuests.forEach((guest) => {
              dispatch(removeUserFromUsers(guest.id));
              dispatch(removeUserFromAvailableUsers(guest.id));
            });
            setSelectedGuests([])
            setIsNextActive(true);
          })
          .catch((error) => {
            selectedGuests.forEach((guest) => {
              dispatch(removeUserFromUsers(guest.id)); // Dispatch the removeUser action with the user ID
              dispatch(removeUserFromAvailableUsers(guest.id));
            });
            setSelectedGuests([])
            setIsNextActive(true);
          }).catch((error) => { console.error(error); });
      };

    return (
        <div className="row w-100 p-0 py-1 py-lg-5 m-0 flex-column flex-lg-row h-100">
            <div id="" className="col-lg-6 order-3 order-lg-2 h-100 d-flex tab-content">
                <div id="nav-gft_embed_select_guest"
                    className="col-12 h-100 w-100 flex-column px-0 px-md-5 m-0 gft-content  flex-nowrap">
                    <div className="gft-header">
                        <h2 className="gft-view-heading mb-3">Guest Management</h2>
                        <p className="gft-view-desc mb-3 mb-lg-5">Please select any Guests you would like to remove from your list.</p>
                    </div>
                { loading ? 
                    <ActionButton text="Loading Guests" isActive={false} onClick={()=>{}} arrow={false} noBg={true}/>
                 : 
                        <form id="gftForm-gft_embed_select_guest" className="gft-form">
                            <div>
                                { checkInData?.availableUsers?.length > 0 ? (
                                        checkInData.availableUsers.map((user, index) => {
                                            return <GuestCard 
                                                    key={index}
                                                    index={index}
                                                    name={`${user.firstName} ${user.lastName}`} 
                                                    onClick={() => {
                                                        setSelectedGuests(prevSelection => {
                                                        const newSelection = [...prevSelection];
                                                    
                                                        // Check if the user is already in the array using their unique ID
                                                        const existingIndex = newSelection.findIndex(
                                                            selectedUser => selectedUser.id === user.id
                                                        );
                                                        if (existingIndex !== -1) {
                                                            // If the user is already in the array, remove them
                                                            newSelection.splice(existingIndex, 1);
                                                        } else {
                                                            // Otherwise, add the user to the array
                                                            newSelection.push(checkInData.availableUsers[index]);
                                                        }
                                                        return newSelection;
                                                        });
                                                    }}
                                                    isRemove={true}
                                                    isSelected={(Array.isArray(selectedGuests) && selectedGuests?.some(checkedUser => checkedUser.id === user.id)) || false}                                             
                                                    />
                                        })
                                    ) : null
                                }
                            </div>
                        </form>
                    }
                    <div className="gft-footer">
                        <div className="gft-buttons d-flex flex-row column-gap-3">
                            { !isMobile ?
                                    <ActionButton text="Back" onClick={handleBack} isActive={true} arrowLeft={true} />
                                :
                                    null
                            }
                            <ActionButton btnPrimary={true} arrowRight={true} text={"Remove selected"} onClick={()=>{handleRemoveGuests()}} isActive={isNextActive} isDisabled={selectedGuests.length === 0 ? true : !isNextActive } arrow={true}/>
                        </div>
                    </div>
                </div>
            </div>
            <BreadCrumbs crumbs={true} activeCrumb={"guestSelect"} backButton={true} backFunction={handleBack} isMobile={isMobile}/>
        </div>
    )
}

export default ManageGuests
