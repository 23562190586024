import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentStep, setUser } from '../../app/store';
import ActionButton from '../interface/button'
import { Fetch } from '../../util/api';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import BreadCrumbs from "../interface/breadcrumbs";
import { Steps, getStepName } from "../../util/helpers";
import { logPageView } from "../../app/analytics";

function Register({isMobile}) {
    const checkInData = useSelector((state) => state.webCheckIn);
    const dispatch = useDispatch();
    const [isNextActive, setIsNextActive] = useState(true);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [consentChecked, setConsentChecked] = useState(true);

    const handleBack = () => {
        dispatch(setCurrentStep(Steps.LOGIN))
    }

    const handleNext = () => {
        setIsNextActive(false)
        // Register User
        setTimeout(() => {
            const newUser = {
                firstName: firstName,
                lastName: lastName,
                phoneNumber: checkInData.user.formattedNumber,
                promotions: consentChecked
            }
            
            if (newUser.firstName && newUser.lastName && newUser.phoneNumber && ( newUser.promotions === true || newUser.promotions === false)) {
                return new Promise((resolve, reject) => {
                    const timeout = new Promise((resolve, reject) => 
                    setTimeout(() => reject('timeout'), 30000) // 30 seconds
                    );
                
                    Promise.race([
                    Fetch(`/webcheckin/CreateUser`, 'POST', newUser)
                        .then(data => {
                            dispatch(setUser({
                                ...checkInData.user,
                                verified: data
                            }));
                            logPageView("registered",{
                                shop_name: checkInData?.shopInfo?.hours?.shopName,
                                shop_country: checkInData?.shopInfo?.country,
                                page: getStepName(checkInData.currentStep).toString(),
                                wait_time: checkInData?.shopInfo?.waitTime,
                                shop_status: checkInData?.shopInfo?.shopStatus,
                                phone_number: checkInData?.user.formattedPhoneNumber
                            })
                            dispatch(setCurrentStep(Steps.GUEST_SELECT));
                            resolve();
                        }),
                    timeout
                    ])
                    .catch(error => {
                        // Send to GA TGM
                        logPageView("registration_error",{
                            shop_name: checkInData?.shopInfo?.hours?.shopName,
                            shop_country: checkInData?.shopInfo?.country,
                            page: getStepName(checkInData.currentStep).toString(),
                            wait_time: checkInData?.shopInfo?.waitTime,
                            shop_status: checkInData?.shopInfo?.shopStatus,
                            error_message: error.message 
                        })
                        setIsNextActive(!isNextActive);
                        setErrorMessage(error.message);
                        setShowError(true);
                        reject(error);  // reject with error
                    });
                });
            } else {
                // Send to GA TGM
                logPageView("registration_error",{
                    shop_name: checkInData?.shopInfo?.hours?.shopName,
                    shop_country: checkInData?.shopInfo?.country,
                    page: getStepName(checkInData.currentStep).toString(),
                    wait_time: checkInData?.shopInfo?.waitTime,
                    shop_status: checkInData?.shopInfo?.shopStatus,
                    error_message: "Unable to process registration, incomplete information submitted."
                })
                setErrorMessage("Unable to process registration, please try again.");
                setShowError(true);
                setIsNextActive(true);
            }
        }, 2000) // 3 seconds
    };

    const handleKeyPress = (event) => {
        const newChar = event.key;
        const currentValue = event.target.value;
    
        // Check if the key is a number or not an allowed character
        if ((!isNaN(newChar) && newChar.trim() !== '') || !/[a-zA-Z-' ]/.test(newChar)) {
          event.preventDefault();
          return;
        }
    
        // Check for more than one space, -, or '
        const countSpaces = (currentValue.match(/ /g) || []).length;
        const countHyphens = (currentValue.match(/-/g) || []).length;
        const countApostrophes = (currentValue.match(/'/g) || []).length;
    
        if ((newChar === ' ' && countSpaces >= 1) || 
            (newChar === '-' && countHyphens >= 1) ||
            (newChar === '\'' && countApostrophes >= 1)) {
          event.preventDefault();
          return;
        }
    
        // Check for consecutive spaces, -, or '
        if (/[ -']{2,}/.test(currentValue + newChar)) {
          event.preventDefault();
          return;
        }
    
        // Check if a space, -, or ' is the first character
        if (currentValue.length === 0 && /[-' ]/.test(newChar)) {
          event.preventDefault();
          return;
        }
    }
    
    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    }
    
    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    }

    const handleConsentClicked = () => {
        setConsentChecked(!consentChecked);

    }

    return (
        <div className="row w-100 p-0 py-1 py-lg-5 m-0 flex-column flex-lg-row h-100">
        <div className="col-lg-6 order-3 order-lg-2 h-100 d-flex tab-content">
                <div id="nav-gft_embed_register" className="col-12 h-100 w-100 flex-column px-0 px-md-5 m-0 gft-content">
                    <div className="gft-header">
                        <h2 className="gft-view-heading mb-3">Create an Account</h2>
                        <p className="gft-view-desc mb-3 mb-lg-5">
                            We couldn't find an account matching your provided mobile phone number.
                            Please create an account below to proceed.  When you select create you agree to receive a one-time sms message to validate your mobile phone number.
                        </p>
                    </div>
                    <form id="gftForm-gft_embed_register" className="gft-form" autoComplete="off">
                        <div className="input-wrap">
                            <label>Mobile Phone Number</label>
                            <PhoneInput
                                country={checkInData?.user?.phoneInputCountry}
                                onlyCountries={['ca','us','nz','au']}
                                value={ checkInData?.user?.phoneInputNumber }
                                countryCodeEditable={false}
                                inputClass={"gft-phone-input-enable w-100"}
                                inputProps={{
                                    name: 'phone',
                                    required: true,
                                    autoFocus: true
                                }}
                                disableCountryGuess={true}
                                preserveOrder={['onlyCountries']}
                                disabled={true}
                            />
                        </div>
                        {/* <div className="input-wrap">
                            <label>Email</label>
                            <input id="registrationEmail" inputmode="email" placeholder="Email (Optional)" />
                        </div> */}
                        <div className="input-wrap">
                            <label>First Name</label>
                            <input id="registrationFirstName" inputMode="text" placeholder="First Name" className="inputs capAplha" onChange={handleFirstNameChange} onKeyDown={handleKeyPress} disabled={!isNextActive}/>
                        </div>
                        <div className="input-wrap">
                            <label>Last Name</label>
                            <input id="registrationLastName" inputMode="text" placeholder="Last Name" className="inputs capAplha" onChange={handleLastNameChange} onKeyDown={handleKeyPress} disabled={!isNextActive}/>
                        </div>
                        <div id="gft_error_checkin" className={`gft_error ${ showError ? '' : 'visually-hidden'}`}>{errorMessage}</div>
                        <div className="pt-3">
                        <input type="checkbox" className="summary-check pe-auto" id="register-check" checked={consentChecked} onChange={handleConsentClicked} readOnly disabled={!isNextActive}/>
                            <label className="d-flex flex-row pe-auto" htmlFor="register-check">
                                <div></div>
                                <span className="remember-me pe-none">I agree to receive marketing communications, updates, and promotional offers from Tommy Guns Original Barbershop. You can unsubscribe at any time. View our <a href="https://tommyguns.com/pages/privacy-policy" target="_blank" rel="noopener noreferrer" className="fw-bold pe-auto" style={{ color: '#fff'}}>Privacy Policy</a>, <a href="https://tommyguns.com/pages/terms-of-service" target="_blank" rel="noopener noreferrer" className="fw-bold pe-auto" style={{ color: '#fff'}}>Terms of Service</a>.</span>
                            </label>
                        </div>
                        <div className={`gft-footer mt-5 ${isMobile ? 'position-relative' : ''}`}>
                            <div className="gft-buttons">
                                { !isMobile ? 
                                    <ActionButton text={"Back"} onClick={handleBack} isActive={true} arrow={true} reversed={true} />
                                :
                                    null
                                }
                                <ActionButton btnPrimary={true} text={"Create"} onClick={handleNext} isActive={isNextActive} arrow={true} isDisabled={firstName===''||lastName===''? true : false} isMobile={isMobile}/>
                            </div>
                        </div>
                    </form>
                    
                </div>
            </div>
            <BreadCrumbs backButton={true} backFunction={handleBack} isMobile={isMobile}/>
        </div>
    )
}

export default Register