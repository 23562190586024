import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setGroupedUsers, removeUserFromGroup, setCurrentStep } from "../../app/store";
import ActionButton from '../interface/button'
import GuestCard from "../interface/guest-card";
import BreadCrumbs from "../interface/breadcrumbs";
import { Steps } from "../../util/helpers";

function GroupServices({isMobile}) {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const checkInData = useSelector((state) => state.webCheckIn);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedGuests, setSelectedGuests] = useState([]);
    const [isNextActive] = useState(true);

    const handleBack = () => {
        //Move back to select guests
        dispatch(setCurrentStep(Steps.GUEST_SELECT))
        dispatch(setGroupedUsers(selectedGuests))
    }

    const handleNext = () => {
        //Update context with selections
        dispatch(setCurrentStep(Steps.SELECT_BARBER))
        dispatch(setGroupedUsers(selectedGuests))
    
    }

    const handleGuestCardClick = (guest) => {
        if (isSelected(guest)) {
            const updatedUsers = checkInData.groupedUsers.filter((user) => user.id !== guest.id);
            dispatch(setGroupedUsers(updatedUsers));
          } else {
            const updatedUsers = [...checkInData.groupedUsers, guest];
            dispatch(setGroupedUsers(updatedUsers));
          }
    };

    const isSelected = (guest) => {
        return checkInData.groupedUsers.some((selectedGuest) => selectedGuest.id === guest.id);
    };

    return (
        <div className="row w-100 p-0 py-1 py-lg-5 m-0 flex-column flex-lg-row h-100">
            <div id="" className="col-lg-6 order-3 order-lg-2 h-100 d-flex tab-content">
                <div id="nav-gft_embed_select_guest"
                    className="col-12 h-100 w-100 flex-column px-0 px-md-5 m-0 gft-content flex-nowrap">
                    <div className="gft-header">
                        <h2 className="gft-view-heading mb-3">Expedite Check-In?</h2>
                        <p className="gft-view-desc mb-3 mb-lg-5">For a faster check-in, select everyone getting identical services otherwise skip.</p>
                    </div>
                { loading ? 
                    <ActionButton text="Loading Guests" isActive={false} onClick={()=>{}} arrow={false} noBg={true}/>
                 : 
                    <form id="gftForm-gft_embed_select_guest" className="gft-form">
                        <div className="gft-guest-wrapper">
                            { checkInData?.users?.length > 0 ? (
                                checkInData.users.map((user, index) => {
                                    return (
                                        <GuestCard
                                            key={index}
                                            index={index}
                                            name={`${user.firstName} ${user.lastName}`}
                                            onClick={()=>{handleGuestCardClick(user)}}                                                                                                   
                                            isChecked={(Array.isArray(checkInData.groupedUsers) && checkInData.groupedUsers?.some(checkedUser => checkedUser.id === user.id)) || false}
                                        />
                                    );
                                })
                                ) : null
                            }
                        </div>
                    </form>
                }
                    <div id="gft_error_checkin" className={`gft_error ${ showError ? '' : 'visually-hidden'}`}>{errorMessage}</div>
                    <div className="gft-buttons d-flex flex-row flex-nowrap align-items-start column-gap-4">
                        <ActionButton text={"Back"} onClick={handleBack} isActive={true} arrow={true} reversed={true}/>
                        <ActionButton text={"Skip"} onClick={handleNext} isActive={isNextActive} noBg={true} arrow={true} isDisabled={checkInData.groupedUsers.length > 0 ? true : !isNextActive }/>
                        <ActionButton text={"Next"} onClick={handleNext} isActive={isNextActive} isDisabled={checkInData.groupedUsers.length < 2 ? true : !isNextActive } arrow={true}/>
                    </div>
                </div>
            </div>
            <BreadCrumbs crumbs={true} activeCrumb={"guestSelect"} isMobile={isMobile} />
        </div>
    )
}

export default GroupServices
