import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatSummaryWaitTime } from '../../util/time-formatting';
import { setUsers } from '../../app/store';

function FinalSummaryGuestCard({ userIndex, user, waitTime, isMobile, cancelServiceCallback }) {
    const dispatch = useDispatch();
    const checkInData = useSelector((state) => state.webCheckIn);
    const [isCancelDisabled, setIsCancelDisabled] = useState(false);

    const handleCancelService = async () => {
        setIsCancelDisabled(true);
      
        const ticket = checkInData.tickets?.find(
          ticket => ticket.visitorId === user.id && ticket.shopId === parseInt(checkInData.shopInfo?.hours.id)
        );
        
        if (ticket) {
          dispatch(cancelServiceCallback(ticket.visitId, user.id))
          .then((response) => {
            setIsCancelDisabled(false);
          })
          .catch((error) => {
            // Handle the error
            //console.log("error",error);
            setIsCancelDisabled(false);
          })
        }
    };
    
    // useEffect(() => {
    // const ticket = checkInData.tickets?.find(
    //     ticket => ticket.visitorId === user.id && ticket.shopId === parseInt(checkInData.shopInfo?.hours.id)
    //     );
    // if (!ticket) {
    //     setIsCancelDisabled(true);
    //     const users = checkInData.users;
    //     const updatedUsers = users.filter((userb) => userb.id !== user.id);
    
    //     dispatch(setUsers(updatedUsers));
    // }

    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[checkInData.tickets]);

    return (
            <div className="mb-2" key={userIndex}>
                <div className="d-flex flex-row justify-content-between align-items-center mb-3">
                    <div className="d-flex flex-row w-100 align-items-end justify-content-between">
                        <h5 className="user-select-none serviceHeading">{user.firstName + ' ' + user.lastName.charAt(0)}</h5>
                        <h6 className="user-select-none serviceHeadingWaitTime">{formatSummaryWaitTime(waitTime)}</h6>
                    </div>
                </div>
                <ul className="mb-4">
                    <li className="d-flex flex-row column-gap-2 w-100 justify-content-between align-items-center">
                        <span className="pt-1 w-5 summaryItem" style={{minWidth: 82}}>BARBER:</span>
                        <span className="d-flex flex-row column-gap-0 w-100 pt-1 summaryItem justify-content-between align-items-center">
                            <div>
                                <p className="ps-1">{user?.selectedBarber[0]?.name}</p>
                            </div>
                            <div className="d-flex flex-column">
                                {waitTime ? (
                                    <button 
                                        className={`btn d-flex badge text-bg-danger rounded p-2 text-uppercase text-wrap ${isMobile ? 'mobileText' : ''}`} 
                                        onClick={!isCancelDisabled ? handleCancelService : null} 
                                        disabled={isCancelDisabled}
                                    >
                                        {isCancelDisabled ? '...' : 'cancel'}
                                    </button>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        </span>
                        {/* <span className="d-flex flex-row column-gap-2 w-100 pt-1 summaryItem justify-content-end">
                            <p className="badge text-bg-light rounded-pill text-uppercase">{formatWaitTimeForGuestCards(checkInData.tickets.find(ticket => ticket.visitorId === user.id)?.waitTime)}</p>
                        </span> */}
                    </li>
                    <li className="d-flex flex-row column-gap-2 w-100 justify-content-between align-items-center">
                        <span className="pt-1 w-5 summaryItem" style={{minWidth: 82}}>SERVICES:</span>
                        <span className="d-flex flex-column row-gap-0 w-100 summaryServices align-items-start summaryItem">
                            {user.selectedServices.map((service, serviceIndex) => {
                                return <p key={serviceIndex} className="p-1 w-100 lh-1">{service.name}</p>
                            })}
                        </span>                             
                    </li>
                </ul>
            </div>
    )
}

export default FinalSummaryGuestCard