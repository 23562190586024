export function formatWaitTime(minutes) {
    let h = Math.floor(minutes / 60);
    let m = minutes % 60;

    return isNaN(minutes) ? "Unavailable" : h === 0 && m === 0 ? "Available Now! (estimated)" : h === 0 ? `${m}m (estimated)` : `${h}h ${m}m (estimated)`;
}

export function formatSummaryWaitTime(minutes) {
  let h = Math.floor(minutes / 60);
  let m = minutes % 60;
  return isNaN(minutes) ? "Unavailable" : h === 0 && m === 0 ? "Est Wait: Available Now!" : h === 0 ? `Est Wait: ${m}m` : `Est Wait: ${h}h ${m}m`;
}


export function formatWaitTimeForGuestCards(minutes) {
  let h = Math.floor(minutes / 60);
  let m = minutes % 60;
  return isNaN(minutes) ? " Unavailable" : h === 0 && m === 0 ? " Available now!" : h === 0 ? ` ${m}m` : ` ${h}h ${m}m`;
}

export function formatWaitTimefromString(timeString) {
  const [hours, minutes] = timeString.split(":").map(Number);

  if (isNaN(hours) || isNaN(minutes)) {
    return "Unavailable";
  } else if (hours === 0 && minutes === 0) {
    return "Available Now! (estimated)";
  } else if (hours === 0) {
    return `${minutes}m (estimated)`;
  } else {
    return `${hours}h ${minutes}m (estimated)`;
  }
}

export function formatShopHoursTime(timeString) {
    const [hours, minutes] = timeString.split(':');
    let period = 'AM';
    let formattedHours = parseInt(hours, 10);
  
    if (formattedHours >= 12) {
      period = 'PM';
      formattedHours %= 12;
    }
  
    if (formattedHours === 0) {
      formattedHours = 12;
    }
  
    return `${formattedHours}:${minutes} ${period}`;
}