import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
//import { setCurrentStep } from '../../app/store';
import { getNextOpenTime } from '../../util/helpers';
import { formatWaitTime } from "../../util/time-formatting";

function BreadCrumbs({ crumbs, activeCrumb, backButton, backFunction, isMobile, waitTimeOverride}) {
  //const dispatch = useDispatch();
  const checkInData = useSelector((state) => state.webCheckIn);
  //const [crumb, setCrumb] = useState(activeCrumb);

  const handleBackClick = () => {
    backFunction();
  }
  // const handleGuestSelect = () => {
  //   setCrumb("guestSelect")
  //   dispatch(setCurrentStep(Steps.GUEST_SELECT));
  // }

  // const handleServiceSelect = () => {
  //   setCrumb("serviceSelect")
  //   dispatch(setCurrentStep(Steps.SELECT_SERVICES));
  // }

  // const handleBarberSelect = () => {
  //   setCrumb("barberSelect")
  //   dispatch(setCurrentStep(Steps.SELECT_BARBER));
  // }

  return (
    <div className="col-12 col-lg-5 order-2 order-lg-3 mt-lg-4 ps-lg-5 px-md-5 d-flex flex-column justify-content-center gft-timeline">
      <div className="col-lg-12 order-0 mt-0 d-flex mb-4 mb-md-3 align-items-center">
        { backButton && isMobile ?
        <button onClick={handleBackClick} className={`btn btn-back p-0 d-flex`}>
          {/* <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
          </svg> */}
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-arrow-left-short" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
          </svg>
        </button>
        : null
        }
          <ul className={`gft-info d-flex flex-column flex-lg-row align-items-end align-items-lg-baseline justify-content-center justify-content-lg-between col-lg-12 gft-info ps-lg-4 p-lg-4 m-0 ${backButton ? '' : ''}`}>
            <li className="me-0 me-lg-4">
              <div className="d-flex flex-row align-items-center justify-content-end">
                <img className="gft-icon me-2" src="/imgs/gft_pin.svg" alt="" />
                <h6 className="d-none d-lg-block">Location</h6>
                <h6 className="d-block d-lg-none">{checkInData.shopInfo?.hours?.shopName}</h6>
              </div>
              <p className="mb-0 d-none d-lg-block">{checkInData.shopInfo?.hours?.shopName}</p>
            </li>
            <li className="mt-1 mt-md-0">
              <div className="d-flex flex-row align-items-center justify-content-end waitTimeText">
                <img className="gft-icon me-2" src="/imgs/gft_clock.svg" alt="" />
                <h6 className="d-none d-lg-block">Wait Time</h6>
                <h6 className="d-block d-lg-none">{checkInData.shopInfo?.shopStatus === 'Full' ? 'FULL FOR THE DAY' : checkInData.shopInfo?.shopStatus === 'Closed' ? `CLOSED - Opens ${getNextOpenTime(checkInData)}` : waitTimeOverride !==null && waitTimeOverride !==undefined ? formatWaitTime(waitTimeOverride) : formatWaitTime(checkInData.shopInfo?.waitTime)}</h6>
              </div>
              <p className="mb-0 d-none d-lg-block">{checkInData.shopInfo?.shopStatus === 'Full' ? 'FULL FOR THE DAY' : checkInData.shopInfo?.shopStatus === 'Closed' ? `CLOSED - Opens ${getNextOpenTime(checkInData)}` : waitTimeOverride !==null && waitTimeOverride !==undefined ? formatWaitTime(waitTimeOverride) : formatWaitTime(checkInData.shopInfo?.waitTime)}</p>
            </li>
            {/* {checkInData.shopInfo?.shopStatus === 'Closed' ?
            <div className="pt-lg-3 pt-0">
              <li className="">
                <div className="d-flex flex-row align-items-center w-aut">
                  <h6>Shop Re-opens:</h6>
                  <p className="p-0 m-0 ps-2">{getNextOpenTime(checkInData)}</p>
                </div>
              </li>
            </div>
            : null} */}
          </ul>
      </div>
      {/* {crumbs ?
        <div className="nav gft-breadcrumbs" id="nav-tab" role="tablist">
          <button className={`nav-link ${crumb === 'guestSelect' ? 'active' : ''}`} id="nav-gft_embed_select_guest-tab" type="button" disabled={crumb === 'guestSelect' ? true : false} onClick={handleGuestSelect}>
            <span>Choose Guests</span>
            <span className="crumb-mobile-text">Guests</span>
            <div></div>
          </button>
          <button className={`nav-link ${crumb === "serviceSelect" ? 'active' : ''}`} id="nav-gft_embed_select_service-tab" type="button" disabled={crumb === 'guestSelect' || crumb === 'serviceSelect' ? true : false} onClick={handleServiceSelect}>
            <span>Choose your Services</span>
            <span className="crumb-mobile-text">Services</span>
            <div></div>
          </button>
          <button className={`nav-link ${crumb === 'barberSelect' ? 'active' : ''}`} id="nav-gft_embed_select_barber-tab" type="button" disabled={crumb === 'guestSelect' || crumb === 'serviceSelect' || crumb === 'barberSelect' ? true : false} onClick={handleBarberSelect}>
            <span>Choose your Barber</span>
            <span className="crumb-mobile-text">Barber</span>
            <div></div>
          </button>
          <button className={`nav-link ${crumb === 'summary' ? 'active' : ''}`} id="nav-gft_embed_summary-tab" type="button" disabled>
            <span>Appointment Summary</span>
            <span className="crumb-mobile-text">Summary</span>
            <div></div>
          </button>
        </div>
        : null} */}
    </div>
  )
}

export default BreadCrumbs;
