import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import ActionButton from './interface/button';
import { useDispatch } from 'react-redux';
import { resetState, setResetState } from '../app/store'
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

const SITES = {
  ca:process.env.REACT_APP_LINKS_CA,
  us:process.env.REACT_APP_LINKS_US,
  nz:process.env.REACT_APP_LINKS_NZ,
  au:process.env.REACT_APP_LINKS_AU,
}

if (!ReactModal.appElement) ReactModal.setAppElement('#webCheckin');

function AppModal({ modalState, shouldRedirect, title, message, buttons:{close,ok,cancel,retry,loading}, country, retryFunction, onModalClose, okFunction, cancelFunction, shopNumber, inactive, countdownValue, reload }) {
  //const checkInData = useSelector(state => state.webCheckIn);
  const [isRetryActive, setIsRetryActive] = useState(true);
  const [countdown, setCountdown] = useState(countdownValue ? countdownValue : inactive ? 45 : 0);
  const dispatch = useDispatch();
  const phoneUtil = PhoneNumberUtil.getInstance();
  const phoneNumber = shopNumber && country ? phoneUtil.parseAndKeepRawInput(shopNumber, country) : null;
 
  useEffect(() => {
    setIsRetryActive(true);
    
    // Event listener to get parent domain
    const receiveMessage = (event) => {
      // Validate the origin
      if (['https://us.tommyguns.com', 'https://ca.tommyguns.com', 'https://tommyguns.co.nz', 'https://tommyguns.com.au'].includes(event.origin)) {
        const parentDomain = event.data;
        // Validate the message origin
        if (parentDomain.includes('tommyguns') && !parentDomain.includes('checkin')) {
          window.parent.postMessage('wci-close-iframe', event.origin);
        } else {
          switch (parentDomain.split(".").pop()) {
            case 'us': window.location.href = SITES.us; break;
            case 'ca': window.location.href = SITES.ca; break;
            case 'nz': window.location.href = SITES.nz; break;
            case 'au': window.location.href = SITES.au; break;
            default: window.location.href = SITES.ca; break;
          }
        }
      }
    };
  
    // Add the event listener
    window.addEventListener('message', receiveMessage);
    
    const timer = setTimeout(() => {
      if (reload === true) {
        window.location.reload();
      }
      if (shouldRedirect) {
        try {
          const parentDomain = window.parent.location.hostname;
          switch (parentDomain.split(".").pop()) {
            case 'us': window.location.href = SITES.us; break;
            case 'ca': window.location.href = SITES.ca; break;
            case 'nz': window.location.href = SITES.nz; break;
            case 'au': window.location.href = SITES.au; break;
            default: window.location.href = SITES.ca; break;
          }
        } catch {
          window.parent.postMessage('wci-get-domain', ['https://us.tommyguns.com', 'https://ca.tommyguns.com', 'https://tommyguns.co.nz', 'https://tommyguns.com.au']);
        }

      }
    }, countdownValue * 1000 || 5000);
  
    // Cleanup
    return () => {
      clearTimeout(timer);
      window.removeEventListener('message', receiveMessage);
    };
  }, [countdownValue, country, reload, shouldRedirect]);
  

  useEffect(() => {
    let timer;

    // Start the countdown if inactive is true
    if (inactive || countdownValue) {
      timer = setInterval(() => {
        setCountdown(prevCount => {
          if (prevCount <= 1) {
            clearInterval(timer);
            dispatch(setResetState(true));
            dispatch(resetState());
            dispatch(setResetState(false));
            handleCloseClick();
            return 0;
          }
          return prevCount - 1;
        });
      }, 1000); // Update every second
    }

    // Clean up the interval on unmount or if inactive becomes false
    return () => {
      if (timer) clearInterval(timer);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inactive, countdownValue]);

  const handleRetryClick = () => {
    setIsRetryActive(!isRetryActive);
    setTimeout(() => {
        if (retryFunction) retryFunction();
    }, 1000); // 2000 milliseconds equals 2 seconds
  }

  const handleCloseClick = () => {
    inactive = false;
    onModalClose();  // Calling the function passed as prop
  }

  const handleOkClick = () => {
    okFunction();  // Calling the function passed as prop
  }

  const handleCancelClick = () => {
    cancelFunction();  // Calling the function passed as prop
  }
  
  return (
    <ReactModal 
      isOpen={modalState}
      shouldFocusAfterRender={false}
      portalClassName={`gft-modal-portal`}
      className={`gft-modal-content ${ loading ? 'loading' : '' }`}
      overlayClassName={`gft-modal-overlay gft-embed`}
      onClick={()=>{}}
    >
    { loading ? <ActionButton btnPrimary={true} text="Loading" isActive={false} onClick={()=>{}} /> :
      <div className={"m-4"}>
        <h2 style={{color: "var(--brandColor)"}}>{title || ""}</h2>
        <p className={"mt-3"} style={{color: "white", fontFamily: "Rubik"}}>{message || ""}</p>
        { shopNumber && country ? <p className={"mt-3"} style={{color: "white", fontFamily: "Rubik"}}>{`Alternatively, Check-In by contacting the Shop at ${shopNumber?phoneUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL):null}.`}</p> : null}
        {(inactive || countdownValue) && countdown > 0 ? (
        <p className={"w-100 mt-3 text-center"} style={{color: "white", fontFamily: "Rubik"}}>
          {countdownValue ? 'Restarting in ' : null}{countdown} seconds...
        </p>
      ) : null}
        { retry || close || ok || cancel ? <div className="gft-modal-buttons justify-content-center">
          { ok ? (<ActionButton arrowLeft={true} text="Log Out" isActive={true} onClick={handleOkClick} />) : null}
          { retry ? (<ActionButton arrowLeft={true} text="Retry" isActive={isRetryActive} onClick={handleRetryClick} />) : null}
          { cancel ? (<ActionButton btnPrimary={true} text="Cancel" isActive={true} onClick={handleCancelClick} />) : null}
          { close ? (<ActionButton btnPrimary={true} text={`${inactive?'Resume':'Close'}`} isActive={true} onClick={handleCloseClick} />) : null}
        </div> : null }
      </div>
    }
  </ReactModal>
  );
}

export default AppModal;
