import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { removeUserTrustedDevice, setCurrentStep } from "../../app/store";
import ActionButton from '../interface/button'
import { Fetch } from '../../util/api';
import GuestCard from "../interface/guest-card-v2";
//import AppModal from "../app-modal";
import BreadCrumbs from "../interface/breadcrumbs";
import { Steps } from "../../util/helpers";

function TrustedDeviceManager({isMobile}) {
    const [loading] = useState(false);
    const dispatch = useDispatch();
    const checkInData = useSelector((state) => state.webCheckIn);
    const [isNextActive, setIsNextActive] = useState(true);
    // const [showError, setShowError] = useState(false);
    // const [errorMessage, setErrorMessage] = useState("");
    const [selectedDevices, setSelectedDevices] = useState([]);
    
    const handleBack = () => {
        dispatch(setCurrentStep(Steps.MANAGE_ACCOUNT))
    }

    const handleRemoveDevices = () => {
        setIsNextActive(false);
        selectedDevices.forEach(device => {
            dispatch(removeUserTrustedDevice(device));
        })
        setSelectedDevices([]);
        setIsNextActive(true);
    };

    useEffect(() => {
        if (checkInData?.user?.trustedDevices?.length === 0) {
            dispatch(setCurrentStep(Steps.MANAGE_ACCOUNT))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[checkInData.user.trustedDevices]);

    return (
        <div className="row w-100 p-0 py-1 py-lg-5 m-0 flex-column flex-lg-row h-100">
            <div id="" className="col-lg-6 order-3 order-lg-2 h-100 d-flex tab-content">
                <div id="nav-gft_embed_select_guest"
                    className="col-12 h-100 w-100 flex-column px-0 px-md-5 m-0 gft-content  flex-nowrap">
                    <div className="gft-header">
                        <h2 className="gft-view-heading mb-3">Trusted Devices</h2>
                        <p className="gft-view-desc mb-3 mb-lg-5">Please select and remove any Trusted Devices you would like.</p>
                    </div>
                { loading ? 
                    <ActionButton text="Loading Guests" isActive={false} onClick={()=>{}} arrow={false} noBg={true}/>
                 : 
                        <form id="gftForm-gft_embed_select_guest" className="gft-form">
                            <div>
                                { checkInData?.user?.trustedDevices?.length > 0 ? (
                                        checkInData.user.trustedDevices.map((device, index) => {
                                            return <GuestCard 
                                                    key={index}
                                                    index={index}
                                                    name={`${device.info}`} 
                                                    onClick={() => {
                                                        setSelectedDevices(prevSelection => {
                                                        const newSelection = [...prevSelection];
                                                    
                                                        // Check if the user is already in the array using their unique ID
                                                        const existingIndex = newSelection.findIndex(
                                                            selectedDevice => selectedDevice.udf === device.udf
                                                        );
                                                        if (existingIndex !== -1) {
                                                            // If the user is already in the array, remove them
                                                            newSelection.splice(existingIndex, 1);
                                                        } else {
                                                            // Otherwise, add the user to the array
                                                            newSelection.push(checkInData.user.trustedDevices[index]);
                                                        }
                                                        return newSelection;
                                                        });
                                                    }}
                                                    isRemove={true}
                                                    isSelected={(Array.isArray(selectedDevices) && selectedDevices?.some(checkedDevice => checkedDevice.udf === device.udf)) || false}                                             
                                                    />
                                        })
                                    ) : null
                                }
                            </div>
                        </form>
                    }
                    <div className="gft-footer">
                        <div className="gft-buttons d-flex flex-row column-gap-3">
                            { !isMobile ?
                                    <ActionButton text="Back" onClick={handleBack} isActive={true} arrowLeft={true} />
                                :
                                    null
                            }
                            <ActionButton btnPrimary={true} arrowRight={true} text={"Remove selected"} onClick={()=>{handleRemoveDevices()}} isActive={isNextActive} isDisabled={selectedDevices.length === 0 ? true : !isNextActive } arrow={true}/>
                        </div>
                    </div>
                </div>
            </div>
            <BreadCrumbs crumbs={true} activeCrumb={"guestSelect"} backButton={true} backFunction={handleBack} isMobile={isMobile}/>
        </div>
    )
}

export default TrustedDeviceManager
