import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import ActionButton from '../interface/button'
import { Fetch } from '../../util/api';
import OTPInput from '../interface/otpInput'
import { setUserVerified, setCurrentStep, setAvailableUsers, setUserRememberMe, addUserTrustedDevice } from '../../app/store';
import BreadCrumbs from "../interface/breadcrumbs";
import { Steps, getStepName } from "../../util/helpers";
import { logPageView } from "../../app/analytics";

function Verification({isMobile, device:{udf,udi}, isSupported}) {
  const dispatch = useDispatch();
  const checkInData = useSelector((state) => state.webCheckIn);
  const [isVerifyActive, setIsVerifyActive] = useState(true);
  const [isRetryActive, setIsRetryActive] = useState(true);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [otp, setOtp] = useState("");
  const [disableVerify, setDisableVerify] = useState(false);
  const [disableRetry, setDisableRetry] = useState(false);
  const [resetOTP, setResetOTP] = useState(false);
  const [rememberMeChecked, setRememberMeChecked] = useState(checkInData.user.trustedDevices.includes(udf))

  const fetchData = (phoneNumber, code) => {
    const timeout = new Promise((resolve, reject) =>
      setTimeout(() => reject('timeout'), 30000) // 30 seconds
    );

    const body = { phoneNumber: checkInData.user.formattedNumber, code: code };
    return Promise.race([
      Promise.all([
        Fetch(`/webcheckin/verify/VerifyOTP`, 'POST', body)
            .then(data => {
            if (data) {
                dispatch(setUserVerified(data));
                return true;
            } else {
                return false;
            }
            })
            .catch(error => {
            throw error;
            }),
      ]),
      timeout,
    ])
      .catch(error => {
        if (error.status === 400) {
          logPageView("otp_error",{
            shop_name: checkInData?.shopInfo?.hours?.shopName,
            shop_country: checkInData?.shopInfo?.country,
            page: getStepName(checkInData.currentStep).toString(),
            wait_time: checkInData?.shopInfo?.waitTime,
            shop_status: checkInData?.shopInfo?.shopStatus,
          })
          setErrorMessage("Submitted code is incorrect");
        } else {
          logPageView("otp_error",{
            shop_name: checkInData?.shopInfo?.hours?.shopName,
            shop_country: checkInData?.shopInfo?.country,
            page: getStepName(checkInData.currentStep).toString(),
            wait_time: checkInData?.shopInfo?.waitTime,
            shop_status: checkInData?.shopInfo?.shopStatus,
          })
          setErrorMessage("Submitted code is incorrect");
        }
        setIsVerifyActive(true);
        setDisableRetry(false);
        setShowError(true);
        return;
      });
  };

  const handleVerifyClick = () => {
    setShowError(false)
    setErrorMessage(null)
    setIsVerifyActive(false)
    setDisableRetry(true)

    if (otp.length === 6) {
      setTimeout(() => {
        fetchData(checkInData.user.formattedNumber, otp).then((data) => {
          if (data) {
            Fetch(`/webcheckin/GetAccountsForPhoneNumber/${checkInData?.user?.formattedNumber}`)
              .then((response) => {
                  dispatch(setAvailableUsers(response));
                  if (rememberMeChecked) {
                    const deviceInfo = {
                      udf: udf,
                      info: `${udi.device}; ${udi.os}; ${udi.browser} ${udi.browserVersion}`
                    }
                    dispatch(addUserTrustedDevice(deviceInfo))
                    logPageView("otp_added_trusted_device",{
                      shop_name: checkInData?.shopInfo?.hours?.shopName,
                      shop_country: checkInData?.shopInfo?.country,
                      page: getStepName(checkInData.currentStep).toString(),
                      wait_time: checkInData?.shopInfo?.waitTime,
                      shop_status: checkInData?.shopInfo?.shopStatus,
                    })
          
                  }
                  dispatch(setCurrentStep(Steps.GUEST_SELECT));
                })
                .then(() => {
                  setIsVerifyActive(true);
                  setDisableRetry(false);
                })
                .catch((error) => {
                  setIsVerifyActive(true);
                  setDisableRetry(false);
                  setShowError(true);
                  setErrorMessage(error.message);
                  logPageView("otp_error",{
                    shop_name: checkInData?.shopInfo?.hours?.shopName,
                    shop_country: checkInData?.shopInfo?.country,
                    page: getStepName(checkInData.currentStep).toString(),
                    wait_time: checkInData?.shopInfo?.waitTime,
                    shop_status: checkInData?.shopInfo?.shopStatus,
                    error_message: error.message
                  })        
                })
          }
        });
      }, 1000)
    } else {
        // Send to GA TGM
        logPageView("otp_error",{
          shop_name: checkInData?.shopInfo?.hours?.shopName,
          shop_country: checkInData?.shopInfo?.country,
          page: getStepName(checkInData.currentStep).toString(),
          wait_time: checkInData?.shopInfo?.waitTime,
          shop_status: checkInData?.shopInfo?.shopStatus,
          error_message: "Invalid One Time Password submitted"
        })
        setErrorMessage("Please enter the code you received");
        setShowError(true);
        setIsRetryActive(true);
        setDisableRetry(false);
    }
  }

  const handleBack = () => {
    dispatch(setCurrentStep(Steps.LOGIN));
  }

  const handleOTPChange = useCallback((newOtp) => {
    setOtp(newOtp);
  }, []);

  const handleRememberMe = () => {
    setRememberMeChecked(!rememberMeChecked);
    dispatch(setUserRememberMe(!checkInData.user.rememberMe));
  }

  return (
    <div className="row w-100 p-0 py-1 py-lg-5 m-0 flex-column flex-lg-row h-100">
      <div className="col-lg-6 order-3 order-lg-2 h-100 d-flex tab-content mb-0">
        <div id="nav-gft_embed_check-in" className="col-12 h-100 w-100 flex-column px-0 px-md-5 m-0 gft-content">
          <div className="gft-header">
            <h2 className="gft-view-heading mb-3">Verification</h2>
            <p className="gft-view-desc mb-3 mb-lg-5">A one time passcode has been sent to your phone number, please enter the code you received and click verify.</p>
          </div>
          <form id="gftForm-gft_embed_check-in" className="gft-form" autoComplete="off">
            <label>Enter Code</label>
            <div className="d-flex flex-column flex-wrap row-gap-3">
              <div className="d-flex flex-column row-gap-1 w-100 align-items-start">
                    <OTPInput isActive={isVerifyActive} isDisabled={checkInData.shopInfo?.shopStatus !== 'Open' ? process.env.REACT_APP_DEV_ENABLED ? false : true : false} onChange={handleOTPChange} resetIndicator={resetOTP} onSubmit={handleVerifyClick} />
                    <div id="gft_error_checkin" className={`gft_error mb-1 ${showError ? '' : 'visually-hidden'}`}>{errorMessage}</div>
              </div>
              <div className="mt-3">
                  <input type="checkbox" className="summary-check" id="verify-check" checked={rememberMeChecked} onChange={handleRememberMe} readOnly disabled={!isSupported || !isVerifyActive}/>
                  <label className="d-flex flex-row pe-none" htmlFor="verify-check">
                      <div></div>
                      <span className="remember-me pe-auto">Trust this device</span>
                  </label>
              </div>
              <div className={`gft-footer mt-5 ${isMobile ? 'position-relative' : ''}`}>
                <div className="gft-buttons w-100 m-0">
                  { !isMobile ?
                      <ActionButton text="Back" onClick={handleBack} isActive={true} arrowLeft={true} />
                  :
                      null
                  }
                  <ActionButton btnPrimary={true} text="Verify" onClick={handleVerifyClick} isActive={isVerifyActive} arrowRight={process.env.REACT_APP_DEV_ENABLED ? true : checkInData.shopInfo?.shopStatus !== "Open" ? false : true} isDisabled={otp.length !== 6 ? true : process.env.REACT_APP_DEV_ENABLED ? false : checkInData.shopInfo?.shopStatus !== 'Open'} isMobile={isMobile} />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <BreadCrumbs backButton={true} backFunction={handleBack} isMobile={isMobile} />
    </div>
  )
}

export default Verification;