import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentStep, setUser } from '../../app/store';
import BreadCrumbs from "../interface/breadcrumbs";
import ActionButton from '../interface/button'
import { Fetch } from '../../util/api';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import { Steps, getStepName } from "../../util/helpers";
import { logPageView } from "../../app/analytics";

function Login({isMobile, device:{udf,udi}, isSupported}) {
  const dispatch = useDispatch();
  const checkInData = useSelector((state) => state.webCheckIn);
  const [isNextActive, setIsNextActive] = useState(true);
  const [phoneInputCountry, setPhoneInputCountry] = useState(checkInData.shopInfo.country || "");
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const validateNumber = (phoneNumber) => {
    return new Promise((resolve, reject) => {
      const timeout = new Promise((resolve, reject) =>
        setTimeout(() => reject('timeout'), 30000) // 30 seconds
      );

      Promise.race([
        Fetch(`/webcheckin/verify/ptn/${phoneNumber}`, 'GET', null)
          .then(data => {
            dispatch(setUser({
              ...checkInData.user,
              verified: data
            }));
            resolve(data); // resolve with the fetched data
          }),
        timeout
      ])
        .catch(error => {
          setIsNextActive(!isNextActive);
          setErrorMessage("Login Service is temporarily unavailable. Please try again later.");
          setShowError(true);
          // Send to GA TGM
          logPageView("login_error",{
            shop_name: checkInData?.shopInfo?.hours?.shopName,
            shop_country: checkInData?.shopInfo?.country,
            page: getStepName(checkInData.currentStep).toString(),
            wait_time: checkInData?.shopInfo?.waitTime,
            shop_status: checkInData?.shopInfo?.shopStatus,
            error_message: "Login Service Unavailable"
          })
          reject(error); // reject with error
        });
    });
  };

  const requestOTP = (phoneNumber) => {
    return new Promise((resolve, reject) => {
      const timeout = new Promise((resolve, reject) =>
        setTimeout(() => reject('timeout'), 30000) // 30 seconds
      );

      Promise.race([
        Fetch(`/webcheckin/verify/RequestOTP`, 'POST', { phoneNumber: phoneNumber })
          .then(data => {
            resolve(data); // resolve with the fetched data
          }),
        timeout
      ])
        .catch(error => {
          setIsNextActive(!isNextActive);
          setErrorMessage("Verification Service is temporarily unavailable. Please try again later.");
          setShowError(true);
          setIsNextActive(true);
          // Send to GA TGM
          logPageView("login_error",{
            shop_name: checkInData?.shopInfo?.hours?.shopName,
            shop_country: checkInData?.shopInfo?.country,
            page: getStepName(checkInData.currentStep).toString(),
            wait_time: checkInData?.shopInfo?.waitTime,
            shop_status: checkInData?.shopInfo?.shopStatus,
            error_message: "Verification Service Unavailable"
          })
          reject(error); // reject with error
        });
    });
  };

  const handleLoginClick = () => {
    setShowError(false);
    setErrorMessage("");
    setIsNextActive(false);

    const phoneUtil = PhoneNumberUtil.getInstance();

    let phoneNumber;
    let isValid = false;
    let errorMessage = "";

    try {
      phoneNumber = phoneUtil.parseAndKeepRawInput(checkInData.user?.phoneInputNumber, phoneInputCountry);
      isValid = phoneUtil.isValidNumber(phoneNumber);
    } catch (e) {
      // Send to GA TGM
      logPageView("login_error",{
        shop_name: checkInData?.shopInfo?.hours?.shopName,
        shop_country: checkInData?.shopInfo?.country,
        page: getStepName(checkInData.currentStep).toString(),
        wait_time: checkInData?.shopInfo?.waitTime,
        shop_status: checkInData?.shopInfo?.shopStatus,
        error_message: "Invalid Phone Number"
      })
      errorMessage = "Please enter a valid phone number";
      setErrorMessage(errorMessage);
      setShowError(true);
      setIsNextActive(true);
      return
    }

    if (!isValid) {
      // Send to GA TGM
      logPageView("login_error",{
        shop_name: checkInData?.shopInfo?.hours?.shopName,
        shop_country: checkInData?.shopInfo?.country,
        page: getStepName(checkInData.currentStep).toString(),
        wait_time: checkInData?.shopInfo?.waitTime,
        shop_status: checkInData?.shopInfo?.shopStatus,
        error_message: "Invalid phone number"
      })
      errorMessage = "Please enter a valid phone number";
      setErrorMessage(errorMessage);
      setShowError(true);
      setIsNextActive(true);
      return
    }

    if (isValid) {

      const formattedNumber = phoneNumber.getCountryCode() === 1 ? phoneUtil.format(phoneNumber, PhoneNumberFormat.E164).replace("+1", "") : phoneUtil.format(phoneNumber, PhoneNumberFormat.E164).replace("+","");

      validateNumber(formattedNumber)
        .then((response) => {
          if (response) {
            // Load state from Azure Table Storage
            const partitionKey = formattedNumber;
            const rowKey = checkInData.shopInfo.hours.id;

            Fetch('/loadState', 'POST', { partitionKey, rowKey })
              .then((state) => {
                dispatch(setUser(state.user));
                if (isSupported && state.user.trustedDevices.some(device => device.udf === udf)) {
                  dispatch(setCurrentStep(Steps.GUEST_SELECT));
                  setIsNextActive(true);
                } else {
                  // Request OTP and move to verification
                  requestOTP(formattedNumber).then((response) => {
                    if (response) {
                      dispatch(setCurrentStep(Steps.VERIFICATION));
                      setIsNextActive(true);
                    }
                  })
                }
                // Send to GA TGM
                logPageView("logged_in",{
                  shop_name: checkInData?.shopInfo?.hours?.shopName,
                  page: getStepName(checkInData.currentStep).toString(),
                  shop_country: checkInData?.shopInfo?.country,
                  user_country: checkInData?.user?.phoneInputCountry,
                  phone_number: formattedNumber,
                  wait_time: checkInData?.shopInfo?.waitTime,
                  shop_status: checkInData?.shopInfo?.shopStatus,
                })                           
              })
              .catch((error) => {
                // Update state
                dispatch(setUser({
                  ...checkInData.user,
                  formattedNumber: formattedNumber
                }));
                // Request OTP and move to verification
                requestOTP(formattedNumber)
                  .then((response) => {
                    if (response) {
                      dispatch(setCurrentStep(Steps.VERIFICATION));
                    }
                  })
              }); 
          } else {
            //throw new Error({message: 'Not Found', status: 404});
          }
        })
        .catch((error) => {
          //Handle Errors
          if (error.response?.status === 404 || error.status === 404) {
            // Update state
            dispatch(setUser({
              ...checkInData.user,
              formattedNumber: formattedNumber
            }));
            //Move to Register - No Account was Found
            dispatch(setCurrentStep(Steps.REGISTER));
          }
        });
    } else {
      //Handle invalid phone number
      setShowError(true);
      setErrorMessage(errorMessage);
      setIsNextActive(true);
    }
  };

  useEffect(() => {
    // Setup Phone Input components initial country to Shop
    if (checkInData?.user?.phoneInputCountry) {
      setPhoneInputCountry(checkInData.user.phoneInputCountry);
    } else if (checkInData?.shopInfo?.country) {
      setPhoneInputCountry(checkInData.shopInfo.country);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="row w-100 p-0 py-1 py-lg-5 m-0 flex-column flex-lg-row h-100">
      <div className="col-lg-6 order-3 order-lg-2 h-100 d-flex tab-content mb-0">
        <div id="nav-gft_embed_check-in" className="col-12 h-100 w-100 flex-column px-0 px-md-5 m-0 d-flex gft-content">
          <div className="gft-header">
            <h2 className="gft-view-heading mb-3">Check-In</h2>
            <p className="gft-view-desc">When you check-in securely with our SMS verification feature, you'll receive a one-time SMS message with a code on your registered mobile phone number.</p>
            <blockquote className="pt-2 small"><cite>* By providing your number, you agree to receive a one-time SMS message for verification.</cite></blockquote>
          </div>
          <form id="gftForm-gft_embed_check-in" className="gft-form mt-2" autoComplete="off">
            <label>Mobile Phone Number</label>
            <div className="d-flex flex-column flex-lg-row align-items-lg-start column-gap-4 flex-wrap row-gap-3">
              <div className="d-flex flex-column row-gap-1">
                <PhoneInput
                  country={checkInData?.user?.phoneInputCountry}
                  onlyCountries={['ca', 'us', 'nz', 'au']}
                  value={checkInData?.user?.phoneInputNumber}
                  onChange={(phone, data) => {
                    if (checkInData?.user?.phoneInputCountry !== data.countryCode && !checkInData.user?.phoneInputCountry !== null) {
                      dispatch(setUser({
                        ...checkInData.user,
                        phoneInputNumber: data.dialCode,
                        phoneInputCountry: data.countryCode,
                        phoneDialCode: data.dialCode
                      }));
                      setPhoneInputCountry(data.countryCode);
                    } else {
                      dispatch(setUser({
                        ...checkInData.user,
                        phoneInputNumber: phone,
                        phoneInputCountry: data.countryCode
                      }));
                      setPhoneInputCountry(data.countryCode);
                    }

                  }}
                  countryCodeEditable={false}
                  inputClass={"gft-phone-input-enable"}
                  inputProps={{
                    name: 'phone',
                    required: true,
                    autoFocus: true
                  }}
                  disableCountryGuess={true}
                  preserveOrder={['onlyCountries']}
                  disabled={checkInData.shopInfo?.shopStatus !== 'Open' ? process.env.REACT_APP_DEV_ENABLED === "true" ? false : true : !isNextActive}
                  onKeyDown={(e) => { if (e.key === 'Enter') { handleLoginClick(); } }}
                />
                <div id="gft_error_checkin" className={`gft_error ${showError ? '' : 'visually-hidden'}`}>{errorMessage}</div>
              </div>
              <div className={`gft-footer ${isMobile ? 'position-relative' : ''}`}>
                  <div className="gft-buttons d-flex flex-row m-lg-0">
                    <ActionButton btnPrimary={true} text={process.env.REACT_APP_DEV_ENABLED === "true" ? "Check-In Now" : checkInData.shopInfo?.shopStatus === "Closed" ? "Closed" : checkInData.shopInfo?.shopStatus === "Full" ? "Full" : "Check-In Now"} onClick={handleLoginClick} isActive={isNextActive} arrowRight={process.env.REACT_APP_DEV_ENABLED === "true" ? true : checkInData.shopInfo?.shopStatus !== "Open" ? false : true} isDisabled={process.env.REACT_APP_DEV_ENABLED === "true" ? false : checkInData.shopInfo?.shopStatus !== 'Open'}  isMobile={isMobile} />
                  </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <BreadCrumbs />
    </div>
  )
}

export default Login;
