import { useState, useEffect, Fragment, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentStep, addUserService, removeUserService, setCurrentGuest } from "../../app/store";
import BreadCrumbs from "../interface/breadcrumbs";
import ActionButton from "../interface/button";
import ServiceCard from "../interface/service-card";
import { Fetch } from "../../util/api";
import AppModal from "../app-modal";
import { Steps, getStepName } from "../../util/helpers";
import { logPageView } from "../../app/analytics";

function ServiceSelection({currentUserIndex, isMobile}) {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const checkInData = useSelector(state => state.webCheckIn);
    const [services, setServices] = useState(checkInData.services || []);
    const [isNextActive, setIsNextActive] = useState(true);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [relatedServices, setRelatedServices] = useState([])
    const containerRef = useRef(null);
    const [hasOverflow, setHasOverflow] = useState(false)
    const [scrollPosition, setScrollPosition] = useState('top')
    const formRef = useRef(null);

    const fetchServices = () => {
        Fetch(`/webcheckin/GetServicesForShop/${checkInData?.shopInfo?.hours?.id}`)
        .then((data) => {
            if(data) {
                setServices(data);
                setLoading(false);
                setShowError(false);
            } else {
                setShowError(true)
            }
        })
        .catch((error) => {
            setShowError(true)
        });
    }

    const handleBack = () => {
      const currentGuest = checkInData.currentGuest;

      if (currentGuest !== 0 ) {
        const nextGuest = checkInData.currentGuest-1;
        dispatch(setCurrentGuest(nextGuest))
        dispatch(setCurrentStep(Steps.SELECT_SERVICES))
      } else {
        dispatch(setCurrentStep(Steps.GUEST_SELECT));
      }
    };
      
    const handleNext = () => {
        if (checkInData.EditEnabled === true) {
          dispatch(setCurrentStep(Steps.SUMMARY));
        } else {
          dispatch(setCurrentStep(Steps.SELECT_BARBER));
        }
    };

    const handleServiceSelection = (service) => {
        let currentGuest = { ...checkInData.users[currentUserIndex] }; // Create a copy, not a reference
    
        // Make sure `selectedServices` exists
        if (!currentGuest.selectedServices) {
          currentGuest.selectedServices = [];
        }
    
        const isServiceSelected = currentGuest.selectedServices.some(selectedService => selectedService.id === service.id);
        if (isServiceSelected) {
          dispatch(removeUserService({userId: currentGuest.id, serviceId:service.id}));
          setRelatedServices(prevServices => {
            return prevServices.filter(s => s.id !== service.id && !service.relations.includes(s.id));
          });
        } else {
          dispatch(addUserService({userId: currentGuest.id, service:service}));
          setRelatedServices(prevServices => {
            return [...prevServices, service];
          });
        }
    }
    
    const currentUserHasServices = () => {
        let currentUser = checkInData.users[currentUserIndex];
        return currentUser.selectedServices && currentUser.selectedServices.length > 0;
    }
         
    const checkContainerHeight = () => { 
      if (containerRef.current) {
        const contentHeight = containerRef.current.scrollHeight;
        const containerHeight = containerRef.current.parentNode.clientHeight;

        if (contentHeight > containerHeight) {
          setHasOverflow(true);
        } else {
          setHasOverflow(false);
        }
      }
    }

    useEffect(() => {
      // Check for Services Height
      const resizeObserver = new ResizeObserver(() => {
        checkContainerHeight();
      });

      const divRef = containerRef.current
      if (divRef) {
        resizeObserver.observe(divRef);
      }
  
      return () => {
        if (divRef) {
          resizeObserver.unobserve(divRef);
        }
      };  
    },[services]);

    useEffect(() => {
      const handleScroll = () => {
          const { scrollTop, scrollHeight, clientHeight } = formRef.current;
          
          if (scrollTop === 0) {
            setScrollPosition('top');
          } else if (scrollTop + clientHeight >= scrollHeight) {
            setScrollPosition('bottom');
          } else {
            setScrollPosition('other');
          }       
      };
  
      if (formRef.current) {
        formRef.current.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (formRef.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          formRef.current.removeEventListener('scroll', handleScroll);
        }
      };
    }, [services]);

    useEffect(() => {
      //Get Services  
      fetchServices()

      //Update Related Services
      if (checkInData?.users[currentUserIndex]?.selectedServices?.length > 0) {
          setRelatedServices(checkInData.users[currentUserIndex].selectedServices)
      }   
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    if (showError) {
      logPageView("select_service_error",{
        shop_name: checkInData?.shopInfo?.hours?.shopName,
        shop_country: checkInData?.shopInfo?.country,
        page: getStepName(checkInData.currentStep).toString(),
        wait_time: checkInData?.shopInfo?.waitTime,
        shop_status: checkInData?.shopInfo?.shopStatus,
        error_message: "Communication Error"
      }) 
      return <AppModal title="Communication Error..." message="This Shop is currently unavailable for online booking due to a technical issue, please try again later." shouldRedirect={false} buttons={{ close: true, retry: true}} retryFunction={fetchServices}/>
    }

    return (
        <div className="row w-100 p-0 py-1 py-lg-5 m-0 flex-column flex-lg-row h-100">
            <div className="col-lg-6 order-3 order-lg-2 h-100 d-flex tab-content">
                <div id="nav-gft_embed_select_service" className="col-12 h-100 w-100 flex-column px-0 px-md-5 m-0 gft-content flex-nowrap">
                    <div className="gft-header">
                        <h2 className="gft-view-heading mb-3">Select Services for <span>{checkInData.users[currentUserIndex].firstName}</span></h2>
                        {/* <h3 className="gft-view-heading mb-3">For {checkInData.users[currentUserIndex].firstName + " " + checkInData.users[currentUserIndex].lastName.charAt(0) }</h3> */}
                        <p className="gft-view-desc mb-3 mb-lg-5">Choose from available services below.</p>
                    </div>
                    {loading ? (
                      <div className="align-items-start mx-auto loading-text">
                        <ActionButton
                          btnPrimary={true}
                          text="Loading Services"
                          isActive={false}
                          onClick={() => {}}
                          noBg={true}
                        />
                      </div>
                    ) : (
                      <form id="gftForm-gft_embed_select_service" className="gft-form" ref={formRef}>
                        <div id="scroll-shadow" ref={containerRef} className={`${hasOverflow ? scrollPosition === 'top' ? 'scroll-shadow-btm' : scrollPosition === 'bottom' ? 'scroll-shadow-top' : 'scroll-shadow-top scroll-shadow-btm' : ''}`}>
                        {Object.entries(services).map(([heading, items], index) => (
                          <Fragment key={index}>
                            {items.length > 0 && (
                              <>
                                <div
                                  className={`pb-1 ${index === 0 ? "mb-3" : "mt-3"} ${
                                    index !== services.length - 1 ? "mb-3" : ""
                                  } serviceHeading`}
                                >
                                  <h5>{heading}</h5>
                                </div>
                                {items.map((item, itemIndex) => {
                                  const isItemRelated = relatedServices.some((service) =>
                                    service.relations.includes(item.id)
                                  );

                                  if (isItemRelated) {
                                    return null;
                                  }

                                  const currentGuest = checkInData.users[currentUserIndex];
                                  const isServiceSelected =
                                    currentGuest && currentGuest.selectedServices
                                      ? currentGuest.selectedServices.some(
                                          (service) => service.id === item.id
                                        )
                                      : false;

                                  return (
                                    <ServiceCard
                                      key={itemIndex}
                                      id={item.id}
                                      title={item.name}
                                      description={item.description}
                                      cost={`$${item.price}`}
                                      isSelected={isServiceSelected}
                                      onClick={() => handleServiceSelection(item)}
                                    />
                                  );
                                })}
                              </>
                            )}
                          </Fragment>
                        ))}
                        </div>
                      </form>
                    )}
                    <div className="gft-footer">
                        <div className="gft-buttons">
                            { !isMobile ?
                                <ActionButton text="Back" onClick={handleBack} isActive={true} arrowLeft={true} />
                            :
                                null
                            }
                            <ActionButton btnPrimary={true}  arrowRight={true} text={"Next"} onClick={handleNext} isActive={isNextActive} isDisabled={!currentUserHasServices() || !isNextActive } arrow={true}/>
                        </div>
                    </div>
                </div>
            </div>
            <BreadCrumbs crumbs={true} activeCrumb="serviceSelect" backButton={true} backFunction={handleBack} isMobile={isMobile} />
        </div>
    )   
}

export default ServiceSelection
