// analytics.js
export const logPageView = (event, params = {}) => {
  try {
    window.dataLayer.push({ 
      event: "checkin_" + event,
      checkin_data: params
    })
  } catch (e) {
    console.log(e)
  }
};

// export const logEvent = (eventName, params) => {
//   window.gtag('event', eventName, params);
// };