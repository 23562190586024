function ServiceCard ({id, title, description, cost, onClick, isSelected}) {
    const handleClick =(e) => {
        onClick(e, id)
    }

    return (
        <>
            <input type="checkbox" className="gft-input" id={`multi-gft_embed_select_service-${id}`} onClick={handleClick} checked={isSelected} readOnly/>
                <label className="gft-service" htmlFor={`multi-gft_embed_select_service-${id}`}>
                    <div className="gft-service-desc">
                        <h4 className="serviceTitle">{title}</h4>
                        <p className="serviceDescription">{description}</p>
                    </div>
                    <h3>{cost}</h3>
                </label>
        </>
    )
}

export default ServiceCard