import axios from "axios";
class FetchError extends Error {
  constructor(message, status, data) {
    super(message);
    this.status = status;
    this.data = data;
  }

  toJSON() {
    return {
      message: this.message,
      status: this.status,
      data: this.data,
    };
  }
}
const baseURI = process.env.REACT_APP_BASE_URI;

if (!baseURI) {
  throw new Error('REACT_APP_BASE_URI environment variable is not set');
} 

export function Fetch(url, method, data) {
  return axios({
    url: `${baseURI}${url}`,
    method: method,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  })
  .then(response => {
    if (response.status < 200 || response.status >= 300) {
      // Handle non-successful status codes
      throw new Error(response.statusText);
    }
    return response.data;
  })
  .catch(error => {
    if (error.response) {
      throw new FetchError(error.message, error.response.status, error.response.data);
    } else if (error.request) {
      throw new FetchError(error.message, null, error.request);
    } else {
      throw new FetchError(error.message);
    }
  });
}



export async function AsyncFetch(url, method, data) {
  try {
    const response = await axios({
      url: `${baseURI}${url}`,
      method: method,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    });

    if (response.status !== 200) {
      throw new Error(response.statusText);
    }

    return response.data;
  } catch (error) {
    throw new Error(error)
  }
}