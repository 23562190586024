import { useState, useEffect, Fragment, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentStep, setCurrentGuest, setUserBarber } from "../../app/store";
import BreadCrumbs from "../interface/breadcrumbs";
import ActionButton from "../interface/button";
import BarberCard from "../interface/barber-card";
import { Fetch } from "../../util/api";
import AppModal from "../app-modal";
import { formatWaitTime } from '../../util/time-formatting';
import { Steps } from "../../util/helpers";

function BarberSelection({currentUserIndex, isMobile, barbersData, retryCallback}) {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const checkInData = useSelector(state => state.webCheckIn);
  const [barbers, setBarbers] = useState(barbersData);
  const [barberWaitTimes, setBarberWaitTimes] = useState(null);
  const [isNextActive, setIsNextActive] = useState(true);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const containerRef = useRef(null);
  const [hasOverflow, setHasOverflow] = useState(false)
  const [scrollPosition, setScrollPosition] = useState('top')
  const formRef = useRef(null);
  const waitTimesFetchInProgress = useRef(false);

  // const fetchBarberData = () => {
  //   const timeout = new Promise((resolve, reject) =>
  //     setTimeout(() => {
  //       reject('timeout');
  //     }, 10000) // 10 seconds
  //   );
  
  //   Promise.race([
  //     (async () => {
  //       try {
  //         const data = await Fetch(`/webcheckin/GetBarbersForShop/${checkInData?.shopInfo?.hours?.id}`);
          
  //         if (data) {
  //           setBarbers(data); // Set barber data
  //           await fetchBarberWaitTimes(); // Wait for this fetch to complete
  //           setLoading(false);
  //           setShowError(false);
  //         } else {
  //           setErrorMessage("Unable to fetch barbers, please try again.");
  //           if (!showError) {
  //             setShowError(true);
  //           }
  //         }
  //       } catch (err) {
  //         setLoading(false);
  //       }
  //     })(),
  //     timeout
  //   ])
  //   .catch((error) => {
  //     console.error("error", error);
  //     setShowError(true);
  //     setErrorMessage(error);
  //     setLoading(false);
  //   });
  // };
  
  const fetchBarberWaitTimes = async () => {
      // Check if fetch is already in progress
    if (waitTimesFetchInProgress.current) {
      return;
    }
    
     // Set fetch as in progress
     waitTimesFetchInProgress.current = true;

    try {
      const data = await Fetch(`/webcheckin/barberWaitTimes/${checkInData?.shopInfo?.hours?.id}`);
      
      if (data) {
        setBarberWaitTimes(data.employee_wait_time); // Set barber data
      } else {
        // Handle null or undefined data
        setBarberWaitTimes([]);
      }
    } catch (error) {
      //console.log("Error fetching barber wait times:", error);
    } finally {
      waitTimesFetchInProgress.current = false;
    }
  };

  const handleBack = () => {
    dispatch(setCurrentStep(Steps.SELECT_SERVICES));
  };

  const handleNext = () => {
    setIsNextActive(false);
    if (checkInData.EditEnabled === true) {
      dispatch(setCurrentStep(Steps.SUMMARY));
    } else {
      if (checkInData.currentGuest === checkInData.users.length - 1) {
        dispatch(setCurrentStep(Steps.SUMMARY));
      } else {
        dispatch(setCurrentGuest(checkInData?.currentGuest + 1));
        dispatch(setCurrentStep(Steps.SELECT_SERVICES));
      }
    }
    setIsNextActive(true);
  };

  const handleRetry = () => {
    retryCallback();
  }

  const handleBarberSelection = (barber) => {
    let currentGuest = { ...checkInData.users[currentUserIndex] }; // Create a copy, not a reference

    // Make sure `selectedBarber` exists
    if (!currentGuest.selectedBarber) {
      currentGuest.selectedBarber = [];
    }

    dispatch(setUserBarber({ userId: currentGuest.id, barber: barber }));
  };

  const currentUserHasBarber = () => {
    let currentUser = checkInData.users[currentUserIndex];
    return currentUser.selectedBarber && currentUser.selectedBarber.length > 0;
  };

  useEffect(() => {
    //fetchBarberData();
    fetchBarberWaitTimes();

    // Set up the interval to update the barber wait times every 15 seconds
    const intervalId = setInterval(() => {
      //fetchBarberData();
      fetchBarberWaitTimes();
    }, 15000);
  
    // Clean up the interval when the component is unmounted or when the dependencies change
    return () => clearInterval(intervalId);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkContainerHeight = () => { 
    if (containerRef.current) {
        const contentHeight = containerRef.current.scrollHeight;
        const containerHeight = containerRef.current.parentNode.clientHeight;

        if (contentHeight > containerHeight) {
        setHasOverflow(true);
        } else {
        setHasOverflow(false);
        }
    }
  }

  useEffect(() => {
    // Check for Services Height
    const resizeObserver = new ResizeObserver(() => {
        checkContainerHeight();
    });

    const divRef = containerRef.current
    if (divRef) {
        resizeObserver.observe(divRef);
    }

    return () => {
        if (divRef) {
        resizeObserver.unobserve(divRef);
        }
    };  
  },[checkInData.users]);
    
  useEffect(() => {
    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = formRef.current;
        
        if (scrollTop === 0) {
          setScrollPosition('top');
        } else if (scrollTop + clientHeight === scrollHeight) {
          setScrollPosition('bottom');
        } else {
          setScrollPosition('other');
        }       
    };

    if (formRef.current) {
      formRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (formRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        formRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [checkInData.users]);
  
  useEffect(() => {
    if (loading && barbers?.employees && barbers?.employees?.length > 0) {
      setLoading(false);
    }
  },[barbers, loading]);

  if (showError)
    return (
      <AppModal
        title="Communication Error..."
        message="This Shop is currently unavailable for online booking due to a technical issue, please try again later."
        shouldRedirect={false}
        buttons={{ close: true, retry: true }}
        retryFunction={handleRetry}
      />
    );

    return (
        <div className="row w-100 p-0 py-1 py-lg-5 m-0 flex-column flex-lg-row h-100">
            <div className="col-lg-6 order-3 order-lg-2 h-100 d-flex tab-content">
                <div id="nav-gft_embed_select_service" className="col-12 h-100 w-100 flex-column px-0 px-md-5 m-0 gft-content flex-nowrap">
                    <div className="gft-header">
                        <h2 className="gft-view-heading mb-3">Select Barber for <span>{checkInData?.users[currentUserIndex]?.firstName}</span></h2>
                        <p className="gft-view-desc mb-3 mb-lg-5">Choose <b>'Next Available'</b> for the fastest service or if you have a preferred barber please select them below.</p>
                    </div>
                    {loading ? (
                      <div className="align-items-start mx-auto loading-text">
                        <ActionButton
                          btnPrimary={true}
                          text="Loading Barbers"
                          isActive={false}
                          onClick={() => {}}
                          noBg={true}
                        />
                      </div>
                    ) : (
                      <form id="gftForm-gft_embed_select_service" className="gft-form" ref={formRef}>
                        <div id="scroll-shadow" ref={containerRef} className={`${hasOverflow ? scrollPosition === 'top' ? 'scroll-shadow-btm' : scrollPosition === 'bottom' ? 'scroll-shadow-top' : 'scroll-shadow-top scroll-shadow-btm' : ''}`}>
                          {barbers && Object.entries(barbers).map(([heading, barbers], index) => (
                            <Fragment key={index}>
                              {barbers.length > 0 && (
                                <>
                                  <div
                                    className={`pb-1 ${index === 0 ? "mb-3" : "mt-3"} ${
                                      index !== barbers.length - 1 ? "mb-3" : ""
                                    } serviceHeading`}
                                  >
                                    <h5>Barbers</h5>
                                  </div>
                                  {barbers.map((barber, itemIndex) => {
                                    const currentGuest =
                                      checkInData.users[currentUserIndex];
                                    const isBarberSelected =
                                      currentGuest &&
                                      currentGuest.selectedBarber &&
                                      currentGuest.selectedBarber.length > 0
                                        ? currentGuest.selectedBarber.some(
                                            (selectedBarber) => selectedBarber.id === barber.id
                                          )
                                        : false;

                                    const barberIndex = barberWaitTimes ? barberWaitTimes.findIndex((employee) => employee.employee_id === parseInt(barber.referenceId)) : -1;
                                    
                                    const barberWaitTime =
                                      barberIndex === -1
                                        ? checkInData.shopInfo.waitTime
                                        : barberWaitTimes[barberIndex === 1 ? 0 : barberIndex].wait_time;
                                    return (
                                      barberWaitTime >= 480 ? 
                                      null 
                                      :
                                      <BarberCard
                                        key={barber.id}
                                        id={barber.id}
                                        title={barber.name}
                                        description={`Wait Time: ${formatWaitTime(barberWaitTime)}`}
                                        isSelected={isBarberSelected}
                                        onClick={() => handleBarberSelection(barber)}
                                      />
                                      
                                    );
                                  })}
                                </>
                              )}
                            </Fragment>
                          ))}
                        </div>
                      </form>
                    )}
                    <div className="gft-footer">
                      <div className="gft-buttons">
                            { !isMobile ?
                                <ActionButton text="Back" onClick={handleBack} isActive={true} arrowLeft={true} />
                            :
                                null
                            }
                          <ActionButton btnPrimary={true} arrowRight={true} text={"Next"} onClick={()=>{handleNext()}} isActive={isNextActive} isDisabled={!currentUserHasBarber() || !isNextActive } arrow={true}/>
                      </div>
                    </div>
                </div>
            </div>
            <BreadCrumbs crumbs={true} activeCrumb="barberSelect" backButton={true} backFunction={handleBack} isMobile={isMobile} />
        </div>
    )   
}

export default BarberSelection