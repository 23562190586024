import React from 'react';
import AppModal from "./app-modal";
import { connect } from 'react-redux';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.removeError = this.removeError.bind(this);
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // TODO Log to Sentry
  }

  removeError() {
    this.setState({ hasError: false });
  }

  render() {
    const { checkInData } = this.props;

    if (this.state.hasError) {
      return (
        <>
        <AppModal
          modalState={this.state.hasError}
          title="Uh Oh...Hold The Scissors!"
          message={`It seems ${checkInData?.shopInfo?.hours?.shopName ? checkInData?.shopInfo?.hours?.shopName : "this Shops"} Online Check-In has encountered a little snip-snap. Don't worry, we're brushing through it and we'll have you into a chair as soon as possible.`}
          shopNumber={checkInData?.shopInfo?.businessNumber}
          country={checkInData?.shopInfo?.country}
          shouldRedirect={false}
          buttons={{ close: false, retry: false, loading: false }}
          onModalClose={this.removeError}
          countdownValue={10}
          reload={true}
        />
        </>
      );
    }

    return this.props.children;
  }
}

const mapStateToProps = (state) => {
  return {
    checkInData: state.webCheckIn
  };
};

export default connect(mapStateToProps)(ErrorBoundary);
