import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ActionButton from "../interface/button"
import { setCurrentStep, setUserVerificationCode } from '../../app/store'
import CryptoJS from 'crypto-js';
import { Steps } from '../../util/helpers';

function DevGateway({isMobile}) {
    const dispatch = useDispatch();
    const checkInData = useSelector(state => state.webCheckIn);
    const [inputValue, setInputValue] = useState("");
    const [isActive, setIsActive] = useState(true)
    const [isVisible, setIsVisible] = useState(false);
    
    const handleInputValueChange = (e) => {
        setInputValue(e.target.value)
    }

    const handleSubmit = () => {
        setIsActive(false)
        setTimeout(()=>{
            if (inputValue === process.env.REACT_APP_DEV_SECRET) {
                const code = CryptoJS.SHA256(process.env.REACT_APP_DEV_SECRET).toString()
                if (
                    checkInData.user &&
                    checkInData.user.verified === true &&
                    !isNaN(checkInData.user.formattedNumber) &&
                    checkInData.user.OTPSent === true &&
                    checkInData.currentStep < Steps.GUEST_SELECT &&
                    checkInData.currentStep !== Steps.DEV_GATEWAY
                  ) {
                    dispatch(setCurrentStep(Steps.GUEST_SELECT));
                  } else {
                    dispatch(setCurrentStep(Steps.LOGIN));
                  }
                dispatch(setUserVerificationCode(code));
            } else {
                //TODO: Add Error Message
            }
            setIsActive(true)
        }
        , 1000)
    }
    
    useEffect(() => {
        setIsVisible(true)
    }, []);

    return (
        <div className={`h-100 w-100 d-flex justify-content-center flex-column align-items-center ps-0 ms-0 row-gap-4 ${isVisible ? 'fade-in' : 'fade-out'}`}>
            <div className='d-flex flex-row align-items-end column-gap-1'>
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="var(--brandColor)" className="bi bi-cone-striped" viewBox="0 0 16 16">
                    <path d="m9.97 4.88.953 3.811C10.159 8.878 9.14 9 8 9c-1.14 0-2.158-.122-2.923-.309L6.03 4.88C6.635 4.957 7.3 5 8 5s1.365-.043 1.97-.12zm-.245-.978L8.97.88C8.718-.13 7.282-.13 7.03.88L6.275 3.9C6.8 3.965 7.382 4 8 4c.618 0 1.2-.036 1.725-.098zm4.396 8.613a.5.5 0 0 1 .037.96l-6 2a.5.5 0 0 1-.316 0l-6-2a.5.5 0 0 1 .037-.96l2.391-.598.565-2.257c.862.212 1.964.339 3.165.339s2.303-.127 3.165-.339l.565 2.257 2.391.598z"/>
                </svg>
                <div>Development access is restricted.</div>
            </div>
            <div className='d-flex row-gap-2 flex-row flex-wrap w-100' style={{maxWidth: 600}}>
                <input className="input mw-100 w-100" type="password" onChange={handleInputValueChange} />
              <div className={`gft-footer ${isMobile ? 'position-relative' : ''}`}>
                    <div className="gft-buttons d-flex flex-row m-lg-0">
                        <ActionButton btnPrimary={true} arrowRight={true} text="Enter" onClick={handleSubmit} isActive={isActive} arrow={true} isDisabled={!isActive} isMobile={isMobile} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DevGateway
